<template>
    <PageApp :namePage="namePage" :title="title" :subtitle="subtitle">
        <v-flex xs12 sm8 md4 xl3>
            <v-card>
                <v-card-text>
                    <v-text-field v-model="credenciais.email" label="E-mail" :color="consts.corEscura" dense></v-text-field>
                    <v-text-field v-model="credenciais.senha" label="Senha" type="password" :color="consts.corEscura" dense></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-btn class="mb-2 ml-2" @click="login" :color="consts.corNeutraEscura" dark small>Entrar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 mr-2" @click="showAlterarSenha" :color="consts.corNeutraEscura" outlined small>Alterar senha</v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>

        <v-dialog v-model="mostrarAlterarSenha" max-width="400">
            <v-card>
                <v-card-title>Altere sua senha</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field v-model="novaSenha.email" label="E-mail" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novaSenha.telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novaSenha.senha" label="Senha"  type="password" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novaSenha.senha_conf" label="Confirme sua senha" type="password" :color="consts.corNeutraEscura" dense />
                    </v-form>
                </v-card-text>
                <v-card-text class="text-left" style="font-weight: 300">
                    <p>
                        Caso você não lembre o seu e-mail e/ou telefone, entre em contato conosco através do e-mail <strong>codezz@codezz.com.br</strong>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 ml-2" @click="alterarSenha" :color="consts.corNeutraEscura" dark outlined>Cadastrar</v-btn>
                    <v-btn class="mb-2 ml-2" @click="mostrarAlterarSenha = false" :color="consts.corNeutraEscura" dark outlined>Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'PageLogin',
    props: [
        'namePage',
        'title',
        'subtitle',
        'profissional' //Quando true, é um profissional; quando false, é um usuário
    ],
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            credenciais: {},
            novaSenha: {},
            mostrarAlterarSenha: false
        }
    },
    methods: {
        alterarSenha() {
            const rotaApi = this.profissional ? 'alterar_senha_profissional' : 'alterar_senha_usuario'

            const bParams = {
                email: this.novaSenha.email,
                telefone: functions.getFoneSemMascara(this.novaSenha.telefone),
                senha: this.novaSenha.senha,
                senha_conf: this.novaSenha.senha_conf
            }
            
            axios.put(`${consts.baseApiUrl}/${rotaApi}`, bParams)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.mostrarAlterarSenha = false
                })
                .catch(functions.showError)
        },
        showAlterarSenha() {
            this.novaSenha = {}
            this.mostrarAlterarSenha = true
        },
        login() {
            if (this.profissional) {
                axios.put(`${consts.baseApiUrl}/login`, this.credenciais)
                    .then(res => {
                        this.$store.commit('setProfissional', res.data)
                        localStorage.setItem(consts.userKeyProfissional, JSON.stringify(res.data))
    
                        this.$router.push({
                            name: 'profissional'
                        })
                    })
                    .catch(functions.showError)
            } else {
                //usuário
                axios.put(`${consts.baseApiUrl}/login_usuario`, this.credenciais)
                    .then(res => {
                        this.$store.commit('setUsuario', res.data)
                        localStorage.setItem(consts.userKeyUsuario, JSON.stringify(res.data))
    
                        this.$router.push({
                            name: 'usuario'
                        })
                    })
                    .catch(functions.showError)
            }
        }
    },
    mounted() {
        if (this.profissional) {
            if (this.$store.state.prfInformado) {
                this.$router.push({
                    name: 'profissional'
                })
            }
        } else {
            //usuário
            if (this.$store.state.usrInformado) {
                this.$router.push({
                    name: 'usuario'
                })
            }
        }
    }
}
</script>

<style>

</style>