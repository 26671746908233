<template>
    <PageLogin namePage="login-usuario" title="Login do usuário" subtitle="Entre com um e-mail e senha do usuário" :profissional="false" />
</template>

<script>
import PageLogin from '@/components/templates/PageLogin'

export default {
    name: 'LoginUsuario',
    components: {
        PageLogin
    }
}
</script>

<style>

</style>