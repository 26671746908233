<template>
    <v-navigation-drawer id="menu-app" v-model="$store.state.menuSistema" v-if="$store.state.prfInformado" absolute temporary>
        <v-list nav dense expand>

            <v-list-group v-for="(menu, i) in menus" :key="i" :value="menu.show" :color="consts.corEscura">

                <template v-slot:activator>
                    <v-list-item-title :style="functions.getCssCorEscura()">
                        {{ menu.title }}
                    </v-list-item-title>
                </template>

                <v-list-item v-for="(subMn, j) in menu.sub" :key="j" :color="consts.corNeutraClara"
                 @click="subMn.to ? encaminharRota(subMn.to) : subMn.fnc()">

                    <v-list-item-icon class="ml-5">
                        <v-icon :style="functions.getCssCorNeutraClara()">
                            {{ subMn.icon }}
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>
                        <span :style="functions.getCssCorNeutraClara()">
                            {{ subMn.title }}
                        </span>
                    </v-list-item-title>

                </v-list-item>
            </v-list-group>

        </v-list>
    </v-navigation-drawer>
</template>

<script>
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'

export default {
    name: 'MenuApp',
    data() {
        return {
            consts,
            functions,
            menus: [
                {
                    title: 'Cadastros',
                    show: true,
                    sub: [
                        {
                            title: 'Serviços',
                            icon: 'mdi-content-cut',
                            to: 'servicos',
                            fnc: null
                        },
                        {
                            title: 'Horários',
                            icon: 'mdi-clock',
                            to: 'horarios',
                            fnc: null
                        },
                        {
                            title: 'Profissionais',
                            icon: 'mdi-account-multiple',
                            to: 'profissionais', 
                            fnc: null
                        }
                    ]
                },
                {
                    title: 'Agenda',
                    show: true,
                    sub: [
                        {
                            title: 'Agenda',
                            icon: 'mdi-calendar',
                            to: 'agenda',
                            fnc: null
                        },
                        {
                            title: 'Agendamentos',
                            icon: 'mdi-calendar-multiple',
                            to: 'agendamentos',
                            fnc: null
                        }
                    ]
                },
                {
                    title: 'Listagens',
                    show: false,
                    sub: [
                        //{title: 'Profissionais', icon: 'mdi-account-multiple',}
                        {
                            title: 'Clientes',
                            icon: 'mdi-account-group',
                            to: 'relListClientes',
                            fnc: null
                        },
                        {
                            title: 'Notificações',
                            icon: 'mdi-bell-ring-outline',
                            to: 'relListNotificacoes',
                            fnc: null
                        }
                    ]
                },
                {
                    title: 'Relatórios da agenda',
                    show: false,
                    sub: [
                        //{title: 'Não realizados', icon: 'mdi-close',}
                        {
                            title: 'Concluídos',
                            icon: 'mdi-check',
                            to: 'relAgendaConcluidos',
                            fnc: null
                        },
                        {
                            title: 'Cancelados',
                            icon: 'mdi-cancel',
                            to: 'relAgendaCancelados',
                            fnc: null
                        },
                        {
                            title: 'Ausentes',
                            icon: 'mdi-thumb-down-outline',
                            to: 'relAgendaAusentes',
                            fnc: null
                        },
                        {
                            title: 'Contagem Serviços',
                            icon: 'mdi-counter',
                            to: 'relAgendaContagem',
                            fnc: null
                        },
                        {
                            title: 'Contagem Clientes',
                            icon: 'mdi-account-group',
                            to: 'relListClientes',
                            fnc: null
                        },
                        {
                            title: 'Clientes',
                            icon: 'mdi-account-search',
                            to: 'relAgendaClientes',
                            fnc: null
                        }
                    ]
                },
                {
                    title: 'Relatórios do financeiro',
                    show: false,
                    sub: [
                        //{title: 'Valor recebido', icon: 'mdi-cash',}
                        //{title: 'Comparativo', icon: 'mdi-compare-horizontal',}
                        //{title: 'Clientes', icon: 'mdi-account-group',}
                        {
                            title: 'Total do mês',
                            icon: 'mdi-sigma',
                            to: 'relFinTotalMes',
                            fnc: null
                        },
                        {
                            title: 'Fluxo de caixa',
                            icon: 'mdi-cash-check',
                            to: 'relFinFluxoCaixa',
                            fnc: null
                        }
                    ]
                },
                {
                    title: 'Outros',
                    show: true,
                    sub: [
                        {
                            title: 'Configurações',
                            icon: 'mdi-cogs',
                            to: 'config',
                            fnc: null,
                        },
                        {
                            title: 'Sair',
                            icon: 'mdi-logout',
                            to: null,
                            fnc: this.logoutProfissional
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        logoutProfissional() {
            localStorage.removeItem(consts.userKeyProfissional)
            this.$store.commit('setProfissional', null)

            this.$router.push( {
                name: 'home'
            })
        },
        encaminharRota(rota) {
            this.$router.push({
                name: rota
            })
        }
    }
}
</script>

<style>

</style>