import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        menuSistema: false,
        profissional: null,
        prfInformado: false,
        usuario: null,
        usrInformado: false
    },
    mutations: {
        setProfissional(state, profissional) {
            state.profissional = profissional
            state.menuSistema = false

            if (profissional) {
                axios.defaults.headers.common['Authorization'] = `bearer ${profissional.token}`
                state.prfInformado = true
            } else {
                delete axios.defaults.headers.common['Authorization']
                state.prfInformado = false
            }
        },
        setUsuario(state, usuario) {
            state.usuario = usuario

            if (usuario) {
                state.usrInformado = true
            } else {
                state.usrInformado = false
            }
        }
    }
})
