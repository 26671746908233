<template>
    <PageApp namePage="new-agendamento-primeiro" title="Marque um agendamento" subtitle="Agende seu horário: " :subtitleStrong="empresa.emp_fantasia">

        <v-col>
            <div class="text-center mb-3" v-if="this.$store.state.usrInformado" outlined>
                <v-btn class="mb-2 mr-2" @click="functions.openSiteUrl(`${consts.siteAgendamentosCodezz}/usuario`)" :color="consts.corEscura" small dark outlined>Consultar agendamentos marcados</v-btn>
            </div>
            
            <div class="text-center mb-3" v-if="empresa.emp_imagem == 'S'" outlined>
                <v-img :src="`${consts.baseApiUrl}/imagem_empresa/${empresa.emp_id}`" height="250px" contain></v-img>
            </div>
            
            <v-expansion-panels v-if="empresa.emp_id > 0" v-model="panels" multiple>
                
                <!-- Profissionais -->
                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Profissional: {{ profissional.prf_nome }}</strong>
                        <v-spacer></v-spacer>
                        <v-btn @click="reset" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                            <v-icon>mdi-reload</v-icon>
                        </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row v-if="profissionais.length > 0">
                            <v-col v-for="(prf, i) in profissionais" :key="i" align-self="center" class="text-center d-flex justify-center">
                                <v-card :class="prf.selected ? 'card-selec' : ''" @click="selectProfissional(prf)" width="120px" height="120px" :color="consts.corClara" outlined>
                                    <span class="text-subtitle-2">{{ prf.prf_nome }}</span>
                                    <v-img :src="prf.imagem" height="80px" contain />
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-alert v-else type="error" dense>
                            Nenhum profissional disponível
                        </v-alert>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Serviços -->
                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Serviço: {{ servico.srv_titulo }}</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list three-line>
                            <template v-for="(srv, i) in servicos">
                                <v-list-item :key="i">
                                    <v-list-item-content>
                                        <v-card :class="srv.selected ? 'card-selec' : ''" @click="selectServico(srv)" :color="consts.corClara" outlined>
                                            <v-card-title class="text-subtitle-2">{{ srv.srv_titulo }}</v-card-title>
                                            <v-card-text class="pt-0" v-if="srv.srv_descricao">{{ srv.srv_descricao }}</v-card-text>
                                            <v-card-text class="pt-0" v-if="srv.srv_preco > 0">Preço: R$ {{ srv.srv_preco }}</v-card-text>
                                        </v-card>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Datas e horários -->
                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Horário: {{ horario.age_data_hora_calc }}</strong>
                        <v-spacer></v-spacer>
                        <v-btn v-if="horarios.length > 0" @click="loadHorarios" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if="!servicoSelecionado">
                            Nenhum serviço selecionado!
                        </div>
                        <div v-else>
                            <div v-if="buscandoAgenda"> 
                                <v-progress-circular indeterminate></v-progress-circular>
                                 Carregando agenda...
                            </div>
                            <div v-else>
                                <v-sheet class="text-center">
                                    <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.prevMonth(diaSelec)">
                                        <v-icon small>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <span> {{ functions.getMesExtenso(diaSelec) }} </span>
                                    <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.nextMonth(diaSelec)">
                                        <v-icon small>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </v-sheet>
                                <v-sheet class="mt-4" height="500">
                                    <v-calendar v-model="diaSelec" type="month" :weekday-format="functions.getDiaTraduzidoCalendario"> 
                                        <template v-slot:day="{ date }">
                                            <v-row class="mt-1 justify-space-around">
                                                <template v-if="(dias.indexOf(date) != -1) && (date != diaSelec)">
                                                    <v-icon :color="consts.corNeutraClara">
                                                        mdi-circle
                                                    </v-icon>
                                                </template>
                                                <template v-if="date == diaSelec">
                                                    <v-icon color="black">
                                                        mdi-circle
                                                    </v-icon>
                                                </template>
                                            </v-row>
                                        </template>
                                    </v-calendar>
                                </v-sheet>
                                <v-sheet>
                                    <p class="mt-5 mb-3 text-center"><strong>{{ functions.getDataString(diaSelec) }}</strong></p>
                                    <div v-if="mostrarComboBox">
                                        <v-select v-model="horaSelec" label="Selecione o horário" :items="functions.getHorariosDiaCombo(diaSelec, this.horarios)" :color="consts.corNeutraEscura"
                                        no-data-text="Nenhum horário disponível para o dia" />
                                        <v-btn @click="selectHorarioCombo(horaSelec)" :color="consts.corNeutraEscura" dark>
                                            Selecionar
                                        </v-btn>
                                    </div>
                                    <v-data-table v-else hide-default-footer :headers="colunas" :items="functions.getHorariosDia(diaSelec, this.horarios)" :items-per-page="-1" 
                                        :item-class="getStyleRow" no-data-text="Nenhum horário disponível para o dia." :header-props="{sortByText: 'Ordenar por...'}">
                                        <template v-slot:[`item.btnActions`]="{ item }">
                                            <v-btn @click="selectHorario(item)" :color="consts.corNeutraEscura" dark>
                                                Selecionar
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-sheet>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Se identifique -->
                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Se identifique: {{ nomeUsuario }}</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card v-if="agendando">
                            <v-card-text>
                                <strong>Você não possui um cadastro?</strong>
                                <br>
                                <a href @click.prevent="setMostrarUsuario">
                                    <strong>Clique aqui</strong>
                                </a>
                                para criar uma conta!
                            </v-card-text>
                            <v-card-text>
                                <v-form>
                                    <v-text-field v-model="usuario.email" label="E-mail" :color="consts.corNeutraEscura" dense />
                                    <v-text-field v-model="usuario.senha" label="Senha" type="password" :color="consts.corNeutraEscura" dense />
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn class="mb-2 ml-2" @click="loginUsuario" :color="consts.corNeutraEscura" dark>Entrar</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn class="mb-2 mr-2" @click="showAlterarSenha" :color="consts.corNeutraEscura" dark outlined>Alterar senha</v-btn>
                            </v-card-actions>
                        </v-card>
                        <div v-else>
                            Nenhum horário selecionado.
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Informações e confirmação do agendamento -->
                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Agendamento</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if="agendando && nomeUsuario != ''">
                            <v-text-field v-model="agendamento.emp_fantasia" label="Empresa" :color="consts.corNeutraEscura" dense readonly />
                            <v-text-field v-model="agendamento.prf_nome" label="Profissional" :color="consts.corNeutraEscura" dense readonly />
                            <v-text-field v-model="agendamento.usr_nome" label="Usuário" :color="consts.corNeutraEscura" dense readonly />
                            <v-text-field v-model="agendamento.srv_titulo" label="Serviço" :color="consts.corNeutraEscura" dense readonly />
                            <v-text-field v-model="agendamento.agd_data_hora_calc" label="Data/Hora" :color="consts.corNeutraEscura" dense readonly />
                            <v-text-field v-model="agendamento.agd_preco" label="Preço" :color="consts.corNeutraEscura" dense readonly />
                            <v-text-field v-model="agendamento.agd_tempo" label="Tempo" :color="consts.corNeutraEscura" dense readonly />
                            <v-btn class="mt-2" @click="dialogAgendar = true" :color="consts.corNeutraClara" dark>Confirma o agendamento?</v-btn>
                        </div>
                        <div v-else>
                            Nenhum horário e/ou usuário selecionados.
                        </div>

                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>
            <v-alert v-else-if="validandoEmpresa" color="blue" style="color: white" dense>
                <v-progress-circular  color="white" indeterminate></v-progress-circular>
                Carregando empresa
            </v-alert>
            <v-alert v-else type="error" dense>
                Link inválido.
            </v-alert>
        </v-col>

        <!-- Dialog de criação de um novo usuário -->
        <v-dialog v-model="mostrarUsuario" max-width="400">
            <v-card>
                <v-card-title>Crie uma conta</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field v-model="novoUsuario.usr_nome" label="Nome" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novoUsuario.usr_sobrenome" label="Sobrenome" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novoUsuario.usr_telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novoUsuario.usr_email" label="E-mail" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novoUsuario.usr_email_conf" label="Confirme seu e-mail" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novoUsuario.usr_senha" label="Senha" type="password" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novoUsuario.usr_senha_conf" label="Confirme sua senha" type="password" :color="consts.corNeutraEscura" dense />
                    </v-form>
                </v-card-text>
                <v-card-text class="text-left" style="font-weight: 300">
                    <p>
                        Ao preencher o formulário acima, você está de acordo com a nossa 
                        <a href class="privacidade" @click.prevent="functions.openPrivacidade()">
                            <strong>Política de Privacidade</strong>
                        </a>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 ml-2" @click="cadastrarUsuario" :color="consts.corNeutraEscura" dark outlined>Cadastrar</v-btn>
                    <v-btn class="mb-2 ml-2" @click="mostrarUsuario = false" :color="consts.corNeutraEscura" dark outlined>Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog pra confirmar o agendamento -->
        <v-dialog v-model="dialogAgendar" max-width="400">
            <v-card>
                <v-card-title></v-card-title> <!-- apenas pra dar o espaçamento -->
                <v-card-text class="text-body-1">
                    <span :style="functions.getCssCorEscura()">
                        Confirma o agendamento?
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="realizarAgendamento()" :color="consts.corNeutraEscura" text>
                        Sim
                    </v-btn>
                    <v-btn @click="dialogAgendar = false" :color="consts.corNeutraEscura" text>
                        Não
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog para alterar a senha do usuário -->
        <v-dialog v-model="mostrarAlterarSenha" max-width="400">
            <v-card>
                <v-card-title>Altere sua senha</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field v-model="novaSenha.email" label="E-mail" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novaSenha.telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novaSenha.senha" label="Senha"  type="password" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="novaSenha.senha_conf" label="Confirme sua senha" type="password" :color="consts.corNeutraEscura" dense />
                    </v-form>
                </v-card-text>
                <v-card-text class="text-left" style="font-weight: 300">
                    <p>
                        Caso você não lembre o seu e-mail e/ou telefone, entre em contato conosco através do e-mail <strong>codezz@codezz.com.br</strong>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 ml-2" @click="alterarSenhaUsuario" :color="consts.corNeutraEscura" dark outlined>Alterar</v-btn>
                    <v-btn class="mb-2 ml-2" @click="mostrarAlterarSenha = false" :color="consts.corNeutraEscura" dark outlined>Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import functions from '@/globalFiles/functions'
import consts from '@/globalFiles/consts'
import axios from 'axios'

export default {
    name: 'NovoAgendamento',
    components: {
        PageApp
    },
    data() {
        return {
            validandoEmpresa: true,
            buscandoAgenda: false,
            consts,
            functions,
            panels: [0],
            mostrarComboBox: true,
            agendando: false,
            mostrarUsuario: false,
            dialogAgendar: false,
            logoInformado: false,
            logoEmpresa: '',
            nomeUsuario: '',
            novoUsuario: {},
            empresa: {},
            profissionais: [],
            profissional: {},
            servicoSelecionado: false,
            servicos: [],
            servico: {},
            dias: [],
            diaSelec: '',
            horaSelec: '',
            horarios: [],
            horario: {},
            usuario: {},
            agendamento: {},
            novaSenha: {},
            mostrarAlterarSenha: false,
            colunas: [
                {
                    text: 'Data/Hora',
                    value: 'age_data_hora_calc'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        alterarSenhaUsuario() {
            const bParams = {
                email: this.novaSenha.email,
                telefone: functions.getFoneSemMascara(this.novaSenha.telefone),
                senha: this.novaSenha.senha,
                senha_conf: this.novaSenha.senha_conf
            }
            
            axios.put(`${consts.baseApiUrl}/alterar_senha_usuario`, bParams)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.mostrarAlterarSenha = false
                })
                .catch(functions.showError)
        },
        cadastrarUsuario() {
            this.mostrarUsuario = false

            const newUsuario = {
                usr_nome: this.novoUsuario.usr_nome,
                usr_sobrenome: this.novoUsuario.usr_sobrenome,
                usr_telefone: functions.getFoneSemMascara(this.novoUsuario.usr_telefone),
                usr_email: this.novoUsuario.usr_email,
                usr_email_conf: this.novoUsuario.usr_email_conf,
                usr_senha: this.novoUsuario.usr_senha,
                usr_senha_conf: this.novoUsuario.usr_senha_conf
            }

            axios.post(`${consts.baseApiUrl}/new_usuario`, newUsuario)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                })
                .catch(functions.showError)
        },
        getStyleRow(item) {
            if (item.selected) {
                return 'style_selecionado'
            } else {
                return ''
            }
        },
        inicializarAgendamento() {
            this.agendando = true
            this.usuario = {}
            this.nomeUsuario = ''

            this.agendamento = {
                prf_id: this.profissional.prf_id,
                usr_id: null,
                //agd_cliente_interno: '',
                srv_id: this.servico.srv_id,
                agd_data_hora: this.horario.age_data_hora,
                agd_preco: this.servico.srv_preco,
                agd_tempo: `${this.servico.srv_horas}:${this.servico.srv_minutos}:00`,

                //Campos abaixo é para visualizar as informações selecionadas até o momento
                emp_fantasia: this.empresa.emp_fantasia,
                prf_nome: this.profissional.prf_nome,
                usr_nome: null, //Este é setado depois
                srv_titulo: this.servico.srv_titulo,
                agd_data_hora_calc: functions.getDataHoraFormatada(this.horario.age_data_hora)
            }
        },
        loadHorarios() {
            this.buscandoAgenda = true;
            this.agendando = false
            //this.diaSelec = '' -- essa variável não precisa ser zerada
            this.dias = []
            this.horarios = []
            this.horario = {}
            this.agendamento = {}
            this.usuario = {}
            this.nomeUsuario = ''

            const dataIni = functions.getDataAgendamento(new Date(), true)

            let dataFim = functions.getDataAgendamento(new Date(), false)
            if (this.diaSelec) {
                dataFim = functions.getDataAgendamento(new Date(this.diaSelec), false)

                if (dataFim < dataIni) {
                    dataFim = functions.getDataAgendamento(new Date(), false)
                    this.diaSelec = ''
                }
            }

            axios.get(`${consts.baseApiUrl}/profissionais/${this.profissional.prf_id}/servicos/${this.servico.srv_id}/horarios/${dataIni}/${dataFim}`)
                .then(r => {
                    let diaAtual = ''
                    r.data.data.forEach(el => {

                        const dAux = el.age_data_hora.substr(0, 10)

                        if (diaAtual != dAux) {
                            diaAtual = dAux

                            this.dias.push(dAux)

                            if (this.diaSelec == '') {
                                this.diaSelec = dAux
                            }
                        }

                        this.horarios.push({
                            ...el,
                            age_data_hora_calc: functions.getDataHoraFormatada(el.age_data_hora),
                            selected: false //Inicializa todos os horários como selected=false
                        })
                    })
                
                    this.buscandoAgenda = false
                })
                .catch(functions.showError)
        },
        loadProfissionais() {
            this.servicoSelecionado = false
            this.agendando = false
            this.profissionais = []
            this.profissional = {}
            this.servicos = []
            this.servico = {}
            this.dias = []
            this.diaSelec = ''
            this.horarios = []
            this.horario = {}
            this.agendamento = {}
            this.usuario = {}
            this.nomeUsuario = ''

            axios.get(`${consts.baseApiUrl}/profissionais_empresa/${this.empresa.emp_id}`)
                .then(r => {
                    r.data.data.forEach(el => {
                        this.profissionais.push({
                            ...el,
                            imagem: el.prf_imagem == 'S' ? `${consts.baseApiUrl}/imagem_profissional/${el.prf_id}` : require('@/assets/profissionais/default-prof.png'),
                            selected: false
                        })
                    })
                })
                .catch(erro => {
                    functions.showError(erro)
                })
        },
        loadServicos() {
            this.servicoSelecionado = false
            this.agendando = false
            this.servicos = []
            this.servico = {}
            this.dias = []
            this.diaSelec = ''
            this.horarios = []
            this.horario = {}
            this.agendamento = {}
            this.usuario = {}
            this.nomeUsuario = ''

            axios.get(`${consts.baseApiUrl}/profissionais/${this.profissional.prf_id}/servicos`)
                .then(r => {
                    r.data.data.forEach(el => {
                        this.servicos.push({ 
                            ...el,
                            selected: false
                        })
                    })
                })
                .catch(functions.showError)
        },
        realizarAgendamento() {
            this.dialogAgendar = false

            axios.post(`${consts.baseApiUrl}/agendamentos`, {
                    prf_id: this.agendamento.prf_id,
                    usr_id: this.agendamento.usr_id,
                    srv_id: this.agendamento.srv_id,
                    agd_data_hora: this.agendamento.agd_data_hora,
                    agd_preco: this.agendamento.srv_preco,
                    agd_tempo: this.agendamento.agd_tempo
                })
                .then(() => {
                    this.$toasted.global.msgSuccess('Agendamento criado com sucesso!')
                    this.reset()

                    this.$router.push({
                        name: 'usuario'
                    })
                })
                .catch(functions.showError)
        },
        reset() {
            this.loadProfissionais()
            this.panels = [0]
        },
        showAlterarSenha() {
            this.novaSenha = {}
            this.mostrarAlterarSenha = true
        },
        selectHorario(item) {
            if (item != this.horario) {
                this.horarios.forEach(el => {
                    el.selected = false
                })

                const age = this.horarios.filter(obj => {
                    return obj.age_id == item.age_id   
                })

                this.horario = item
                age[0].selected = true

                this.inicializarAgendamento()

                if (this.$store.state.usrInformado) {
                    this.nomeUsuario = this.$store.state.usuario.usr_nome
                            
                    this.agendamento.usr_nome = this.nomeUsuario
                    this.agendamento.usr_id = this.$store.state.usuario.usr_id
                            
                    this.panels = [4]
                } else {
                    this.panels = [3]
                }
            } 
        },
        selectHorarioCombo(data) {
            const aItem = this.horarios.filter(obj => {
                return obj.age_data_hora == data
            })

            if (aItem.length <= 0) {
                functions.showError('Você deve selecionar um horário.')
            } else {
                this.selectHorario(aItem[0])
            }
        },
        selectProfissional(item) {
            if (item != this.profissional) {
                this.profissionais.forEach(el => {
                    el.selected = false
                })

                const prf = this.profissionais.filter(obj => {
                    return obj.prf_id == item.prf_id   
                })

                this.panels = [1]
                this.profissional = item
                prf[0].selected = true

                this.loadServicos()
            } 
        },
        selectServico(item) {
            this.servicoSelecionado = false
            if (item !== this.servico) {
                this.servicos.forEach(el => {
                    el.selected = false
                })
    
                const srv = this.servicos.filter(obj => {
                    return obj.srv_id == item.srv_id
                })
    
                this.panels = [2]
                this.servico = item
                srv[0].selected = true

                this.servicoSelecionado = true;
                this.loadHorarios()
            }
        },
        setMostrarUsuario() {
            this.novoUsuario = {}
            this.mostrarUsuario = true
        },
        loginUsuario() {
            this.nomeUsuario = ''

            //Faz o login do usuário na plataforma
            axios.put(`${consts.baseApiUrl}/login_usuario`, this.usuario)
                .then(res => {
                    this.$store.commit('setUsuario', res.data)
                    localStorage.setItem(consts.userKeyUsuario, JSON.stringify(res.data))

                    this.nomeUsuario = res.data.usr_nome
                    
                    this.agendamento.usr_nome = this.nomeUsuario
                    this.agendamento.usr_id = res.data.usr_id
                    
                    this.panels = [4]
                })
                .catch(functions.showError)
        }
    },
    mounted() {
        this.empresa = {}
        this.logoInformado = false
        this.logoEmpresa = ''
        this.validandoEmpresa = true

        if (this.$route.params.link != '') {
            axios.get(`${consts.baseApiUrl}/empresa/${this.$route.params.link}`)
                .then(r => {
                    this.empresa = r.data.data

                    if (this.empresa.emp_id > 0) {
                        this.loadProfissionais()                       
                    }
                    
                    this.validandoEmpresa = false
                })
                .catch(err => {
                    functions.showError(err)
                    this.validandoEmpresa = false
                })
        }
    }
}
</script>

<style>
    .card-selec {
        border: 5px solid rgb(38, 73, 92) !important;
    }
    .style_selecionado {
        color: rgb(0, 0, 255);
        font-weight: bold;
    }
</style>