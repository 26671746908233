<template>
    <PageApp namePage="cad-horarios-bloqueados" title="Horários bloqueados" subtitle="Horários bloqueados para o serviço" :subtitleStrong="srvNome">
        
        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Formulário</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-select v-model="horario.hrb_dia_semana" :items="dias" label="Dia da semana" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="horario.hrb_hora_inicial" label="Hora inicial" type="time" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="horario.hrb_hora_final" label="Hora final" type="time" :color="consts.corNeutraEscura" dense />
                    </v-form>
                    <v-row class="mt-3">
                        <v-btn class="ml-2 mb-2" v-if="mode === 'save'" @click="save" :color="consts.corNeutraClara" dark>Salvar</v-btn>
                        <v-btn class="ml-2 mb-2" v-if="mode === 'remove'" @click="remove" :color="consts.corNeutraEscura" dark>Excluir</v-btn>
                        <v-btn class="ml-2 mb-2" @click="reset" :color="consts.corClara">Cancelar</v-btn>
                    </v-row>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Listagem</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-listagem" hide-default-footer :headers="colunas" :items="horariosBloq"
                     no-data-text="Nenhum horário cadastrado para o serviço" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                        <template v-slot:[`item.btnActions`]="{ item }">
                            <v-btn class="mr-2" @click="loadHorario(item)" :color="consts.corNeutraClara" x-small dark>Editar</v-btn>
                            <v-btn @click="loadHorario(item, 'remove')" :color="consts.corNeutraEscura" x-small dark>Remover</v-btn>
                        </template>
                    </v-data-table>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'CadHorariosBloqueados',
    props: [
        'srvId',
        'srvNome'
    ],
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            mode: 'save',
            panels: [0, 1],
            horario: {},
            horariosBloq: [],
            colunas: [
                {
                    text: 'Dia da semana',
                    value: 'hrb_dia_semana'
                },
                {
                    text: 'Hora inicial',
                    value: 'hrb_hora_inicial'
                },
                {
                    text: 'Hora final',
                    value: 'hrb_hora_final'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ],
            dias: [
                {
                    text: 'Segunda',
                    value: 'SEG'
                },
                {
                    text: 'Terça',
                    value: 'TER'
                },
                {
                    text: 'Quarta',
                    value: 'QUA'
                },
                {
                    text: 'Quinta',
                    value: 'QUI'
                },
                {
                    text: 'Sexta',
                    value: 'SEX'
                },
                {
                    text: 'Sábado',
                    value: 'SAB'
                },
                {
                    text: 'Domingo',
                    value: 'DOM'
                },
            ]
        }
    },
    methods: {
        loadHorario(hrb, mode = 'save') {
            this.mode = mode
            this.horario = { ...hrb }
        },
        loadHorariosBloqueados() {
            axios.get(`${consts.baseApiUrl}/horarios_bloqueados/${this.srvId}`)
                .then(r => {
                    this.horariosBloq = r.data.data
                })
                .catch(functions.showError)
        },
        remove() {
            const id = this.horario.hrb_id
            axios.delete(`${consts.baseApiUrl}/horarios_bloqueados/${id}`)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.horario = {}
            this.loadHorariosBloqueados()
        },
        save() {
            const method = this.horario.hrb_id ? 'put' : 'post'
            const id = this.horario.hrb_id ? `/${this.horario.hrb_id}` : ''

            this.horario.prf_id = this.$store.state.profissional.prf_id
            this.horario.srv_id = this.srvId

            axios[method](`${consts.baseApiUrl}/horarios_bloqueados${id}`, this.horario)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        }
    },
    mounted() {
        if (this.$store.state.prfInformado) {
            if (this.srvId > 0) {
                this.loadHorariosBloqueados()
            } else {
                this.$router.push({
                    name: 'profissional'
                })
            }
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>

</style>