<template>
    <PageLogin namePage="login-profissional" title="Login do profissional" subtitle="Entre com um e-mail e senha do profissional" :profissional="true" />
</template>

<script>
import PageLogin from '@/components/templates/PageLogin'

export default {
    name: 'LoginProfissional',
    components: {
        PageLogin
    }
}
</script>

<style>

</style>