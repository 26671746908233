//Constantes que podem ser utilizadas/reaproveitadas no sistema inteiro

const producaoCodezz = true

export default {

    // Exemplo de cores (item 10): https://en.99designs.com.br/blog/creative-inspiration/color-combinations/
    corClara: 'rgb(229, 229, 220)',       //#e5e5dc
    corEscura: 'rgb(38, 73, 92)',         //#26495c
    corNeutraClara: 'rgb(196, 163, 90)',  //#c4a35a
    corNeutraEscura: 'rgb(198, 107, 61)', //#c66b3d

    baseApiUrl: producaoCodezz ? 'https://api-agendamentos.codezz.com.br:3002' : 'http://localhost:3002',

    userKeyProfissional: '__agendamentos_profissional_codezz',
    userKeyUsuario: '__agendamentos_usuario_codezz',

    siteCodezz: 'https://www.codezz.com.br',
    siteAgendamentosCodezz: producaoCodezz ? 'https://agendamentos.codezz.com.br' : 'http://localhost:8080',

    msgContatoCodezz: 'entre em contato com a Codezz (codezz@codezz.com.br)'
}
