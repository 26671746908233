<template>
    <PageApp namePage="agendamento-page" title="Agendamento" subtitle="Visualize o agendamento" :subtitleStrong="agendamento.agd_iid">
        <v-flex>
            <v-card>
                <v-card-text>
                    <v-form>
                        <v-text-field v-model="agendamento.agd_iid" label="Código" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.prf_nome" label="Profissional" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.srv_titulo" label="Serviço" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.usr_nome_aux" label="Nome do cliente (usuário)" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.agd_cliente_interno" label="Cliente (interno)" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.agd_data_hora_calc" label="Data/Hora" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.agd_preco" label="Preço" type="number" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.agd_tempo" label="Tempo" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.agd_status_calc" label="Status" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.agd_criacao_calc" label="Criação" :color="consts.corNeutraEscura" dense readonly />
                        <v-text-field v-model="agendamento.agd_id" label="Código interno" :color="consts.corNeutraEscura" dense readonly />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-col class="text-center">
                        <v-btn class="ml-2 mb-2" v-if="agendamento.agd_status == 'MAR'" @click="confirmaCancel = true" :color="consts.corNeutraEscura" dark>
                            Cancelar agendamento
                        </v-btn>
                        <v-btn class="ml-2 mb-2" v-if="agendamento.agd_status == 'MAR'" @click="confirmaAusente = true" :color="consts.corNeutraEscura" dark>
                            Marcar como ausente
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-flex>

        <!-- Dialog pra confirmar o cancelamento -->
        <v-dialog v-model="confirmaCancel" max-width="300">
            <v-card>
                <v-card-title></v-card-title> <!-- apenas pra dar o espaçamento -->
                <v-card-text class="text-body-1">
                    <span :style="functions.getCssCorEscura()">
                        Confirma o cancelamento do agendamento?
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancelarAgendamento()" :color="consts.corNeutraEscura" text>
                        Sim
                    </v-btn>
                    <v-btn @click="confirmaCancel = false" :color="consts.corNeutraEscura" text>
                        Não
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmaAusente" max-width="300">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text class="text-body-1">
                    <span :style="functions.getCssCorEscura()">
                        Marcar agendamento como ausente?
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="marcarAgendamentoAusente()" :color="consts.corNeutraEscura" text>
                        Sim
                    </v-btn>
                    <v-btn @click="confirmaAusente = false" :color="consts.corNeutraEscura" text>
                        Não
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </PageApp >
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import functions from '@/globalFiles/functions'
import consts from '@/globalFiles/consts'
import axios from 'axios'

export default {
    name: 'AgendamentoPage',
    props: [
        'agdId'
    ],
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            confirmaCancel: false,
            confirmaAusente: false,
            agendamento: {}
        }
    },
    methods: {
        cancelarAgendamento() {
            this.confirmaCancel = false

            let b = {}
            if (this.agendamento.usr_id) {
                b = {
                    agd_iid: this.agendamento.agd_iid,
                    prf_id: this.agendamento.prf_id,
                    srv_id: this.agendamento.srv_id,
                    agd_status: this.agendamento.agd_status,
                    usr_id: this.agendamento.usr_id
                }
            } else {
                b = {
                    agd_iid: this.agendamento.agd_iid,
                    prf_id: this.agendamento.prf_id,
                    srv_id: this.agendamento.srv_id,
                    agd_status: this.agendamento.agd_status,
                    agd_cliente_interno: this.agendamento.agd_cliente_interno
                }
            }

            axios.put(`${consts.baseApiUrl}/agendamentos/${this.agdId}`, b)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.loadAgendamento()
                })
                .catch(functions.showError)
        },
        loadAgendamento() {
            axios.get(`${consts.baseApiUrl}/agendamentos/${this.agdId}`)
                .then(r => {
                    this.agendamento = r.data.data
                    
                    this.agendamento.agd_data_hora_calc = functions.getDataHoraFormatada(this.agendamento.agd_data_hora)
                    this.agendamento.agd_criacao_calc = functions.getDataHoraFormatada(this.agendamento.agd_criacao)

                    if (this.agendamento.usr_nome) {
                        this.agendamento.usr_nome_aux = `${this.agendamento.usr_nome} ${this.agendamento.usr_sobrenome}`
                    } else {
                        this.agendamento.usr_nome_aux = ''
                    }

                    if (this.agendamento.agd_status == 'MAR') {
                        this.agendamento.agd_status_calc = 'Marcado'
                    }
                    else if (this.agendamento.agd_status == 'CON') {
                        this.agendamento.agd_status_calc = 'Concluído'
                    }
                    else if (this.agendamento.agd_status == 'PAG') {
                        this.agendamento.agd_status_calc = 'Pago'
                    }
                    else if (this.agendamento.agd_status == 'CAN') {
                        this.agendamento.agd_status_calc = 'Cancelado'
                    }
                    else if (this.agendamento.agd_status == 'AUS') {
                        this.agendamento.agd_status_calc = 'Ausente'
                    }
                })
                .catch(functions.showError)
        },
        marcarAgendamentoAusente() {
            this.confirmaAusente = false

            let b = {}
            if (this.agendamento.usr_id) {
                b = {
                    agd_iid: this.agendamento.agd_iid,
                    prf_id: this.agendamento.prf_id,
                    srv_id: this.agendamento.srv_id,
                    agd_status: this.agendamento.agd_status,
                    usr_id: this.agendamento.usr_id
                }
            } else {
                b = {
                    agd_iid: this.agendamento.agd_iid,
                    prf_id: this.agendamento.prf_id,
                    srv_id: this.agendamento.srv_id,
                    agd_status: this.agendamento.agd_status,
                    agd_cliente_interno: this.agendamento.agd_cliente_interno
                }
            }

            axios.put(`${consts.baseApiUrl}/ausentar_agendamento/${this.agdId}`, b)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.loadAgendamento()
                })
                .catch(functions.showError)
        }
    },
    mounted() {
        if (this.$store.state.prfInformado && this.agdId > 0) {
            this.loadAgendamento()
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>

</style>