<template>
    <PageApp namePage="home-page" title="Controle de Agendamentos da Codezz" subtitle="Seja bem vindo!">
        <div class="text-center">
            <div>
                <v-btn class="mb-2" @click="loginUsuario" :color="consts.corNeutraEscura" outlined>Entre na plataforma</v-btn>
            </div>
            <div>
                <v-btn class="mr-2 mb-2" @click="marcarAgendamentos" :color="consts.corNeutraEscura" outlined>Marque um agendamento</v-btn>
                <v-btn class="mr-2 mb-2" @click="consultarAgendamentos" :color="consts.corNeutraEscura" outlined>Consultar meus agendamentos</v-btn>
            </div>
            <div class="mt-5 mb-5">
                <v-card>
                    <v-card-title>Crie uma conta</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field v-model="usuario.usr_nome" label="Nome" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="usuario.usr_sobrenome" label="Sobrenome" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="usuario.usr_telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="usuario.usr_email" label="E-mail" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="usuario.usr_email_conf" label="Confirme seu e-mail" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="usuario.usr_senha" label="Senha" type="password" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="usuario.usr_senha_conf" label="Confirme sua senha" type="password" :color="consts.corNeutraEscura" dense />
                        </v-form>
                    </v-card-text>
                    <v-card-text class="text-left" style="font-weight: 300">
                        <p>
                            Ao preencher o formulário acima, você está de acordo com a nossa 
                            <a href class="privacidade" @click.prevent="functions.openPrivacidade()">
                                <strong>Política de Privacidade</strong>
                            </a>
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="mb-2 ml-2" @click="cadastrarUsuario" :color="consts.corNeutraEscura" dark>Cadastrar</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
            <div>
                <v-btn class="mr-2 mb-2" @click="loginProfissional" :color="consts.corNeutraEscura" outlined>Entrar como profissional</v-btn>
                <v-btn class="mr-2 mb-2" @click="newEmpresa" :color="consts.corNeutraEscura" outlined>Cadastrar nova empresa</v-btn>
            </div>
        </div>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'HomePage',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            usuario: {}
        }
    },
    methods: {
        cadastrarUsuario() {
            const novoUsuario = {
                usr_nome: this.usuario.usr_nome,
                usr_sobrenome: this.usuario.usr_sobrenome,
                usr_telefone: functions.getFoneSemMascara(this.usuario.usr_telefone),
                usr_email: this.usuario.usr_email,
                usr_email_conf: this.usuario.usr_email_conf,
                usr_senha: this.usuario.usr_senha,
                usr_senha_conf: this.usuario.usr_senha_conf,
            }

            axios.post(`${consts.baseApiUrl}/new_usuario`, novoUsuario)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.usuario = {}
                })
                .catch(functions.showError)
        },
        consultarAgendamentos() {
            this.$router.push({
                name: 'consultaAgendamentos'
            })
        },
        loginProfissional() {
            this.$router.push({
                name: 'loginProfissional'
            })
        },
        loginUsuario() {
            this.$router.push({
                name: 'loginUsuario'
            })
        },
        marcarAgendamentos() {
            this.$router.push({
                name: 'selecionarEmpresa'
            })
        },
        newEmpresa() {
            this.$router.push({
                name: 'novaEmpresa'
            })
        }
    }
}
</script>

<style>

</style>