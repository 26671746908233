<template>
    <PageApp :namePage="namePage" :title="title" :subtitle="subtitle" :subtitleStrong="subtitleStrong">
        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Parâmetros</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card>
                        <v-card-text>
                            <v-select v-model="pProfissional" :items="listProfissionais" label="Profissional" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="pDataIni" label="Data inicial" v-mask="'##/##/####'" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="pDataFim" label="Data final" v-mask="'##/##/####'" :color="consts.corNeutraEscura" dense />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="ml-2 mb-2" @click="carregarRelatorio" :color="consts.corNeutraEscura" outlined dark>
                                Carregar relatório
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Relatório</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <slot></slot>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'PageRelatorio',
    props: [
        'namePage',
        'title',
        'subtitle',
        'subtitleStrong',
        'permissions', //Pode ser "", "ADMIN", "REL_AGENDA", "REL_FINANC"
        'fncCarregaRel'
    ],
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            panels: [0],
            pProfissional: -1,
            pDataIni: '',
            pDataFim: '',
            listProfissionais: []
        }
    },
    methods: {
        carregarRelatorio() {
            this.fncCarregaRel(this.pProfissional, this.pDataIni, this.pDataFim)
            this.panels = [1]
        }
    },
    mounted() {
        if (this.$store.state.prfInformado) {
            if ((this.permissions == 'ADMIN'      && this.$store.state.profissional.prf_admin          != 'S') ||
                (this.permissions == 'REL_AGENDA' && this.$store.state.profissional.emp_rel_agenda     != 'S') ||
                (this.permissions == 'REL_FINANC' && this.$store.state.profissional.emp_rel_financeiro != 'S'))
            {
                this.$router.push({
                    name: 'no-permission'
                })
            }
            else {

                this.pDataIni = functions.getPrimeiroDiaMes()
                this.pDataFim = functions.getUltimoDiaMes()

                if (this.$store.state.profissional.prf_admin == 'S') {
                    axios.get(`${consts.baseApiUrl}/profissionais`)
                        .then(r => {
                            r.data.data.forEach(el => {
                                this.listProfissionais.push({
                                    text: el.prf_nome,
                                    value: el.prf_id
                                })
                            })
                        })
                        .catch(functions.showError)
                } else {
                    this.listProfissionais = [
                        {
                            text: this.$store.state.profissional.prf_nome,
                            value: this.$store.state.profissional.prf_id
                        }
                    ]
                }
            }
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>

</style>