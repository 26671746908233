<template>
    <PageRelatorio namePage="rel-list-notificacoes" title="Listagem de notificações" subtitle="Informe o profissional e o período para obter os dados"
     permissions="" :fncCarregaRel="carregarRelatorio">

        <v-data-table class="tabela-rel-list-notificacoes" hide-default-footer :headers="colunasRel" :items="dadosRel"
         no-data-text="Nenhum registro encontrado" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">

            <template v-slot:[`item.not_data_hora`]="{ item }">
                {{ functions.getDataHoraFormatada(item.not_data_hora) }}
            </template>
            <template v-slot:[`item.agd_data_hora`]="{ item }">
                {{ functions.getDataHoraFormatada(item.agd_data_hora) }}
            </template>

        </v-data-table>

    </PageRelatorio>
</template>

<script>
import PageRelatorio from '@/components/templates/PageRelatorio'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'RelListNotificacoes',
    components: {
        PageRelatorio
    },
    data() {
        return {
            functions,
            dadosRel: [],
            colunasRel: [
                {
                    text: 'Data/Hora',
                    value: 'not_data_hora'
                },
                {
                    text: 'Serviço',
                    value: 'srv_titulo'
                },
                {
                    text: 'Agendamento',
                    value: 'agd_data_hora'
                },
                {
                    text: 'Cliente',
                    value: 'usr_nome'
                },
                {
                    text: 'Status',
                    value: 'not_status'
                }
            ]
        }
    },
    methods: {
        carregarRelatorio(profissional, dataIni, dataFim) {
            this.dadosRel = []

            axios.put(`${consts.baseApiUrl}/rel_list_notificacoes`, {
                    profissional: profissional,
                    data_ini: dataIni,
                    data_fim: dataFim
                })
                .then(r => {
                    this.dadosRel = r.data.data
                })
                .catch(err => {
                    functions.showError(err)
                })
        }
    }
}
</script>

<style>

</style>