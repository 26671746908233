<template>
    <v-app-bar id="header-app" app :color="consts.corNeutraClara">

        <v-app-bar-nav-icon @click="visualizarMenu" v-if="$store.state.prfInformado" :style="functions.getCssCorClara()" />

        <router-link style="text-decoration: none; color: inherit;" :to="getRedirecionamento()">
            <v-toolbar-title :style="functions.getCssCorClara()" style="font-weight: 500">
                Seja bem vindo!
            </v-toolbar-title>
        </router-link>

    </v-app-bar>
</template>

<script>
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'

export default {
    name: 'HeaderApp',
    data() {
        return {
            consts,
            functions
        }
    },
    methods: {
        getRedirecionamento() {
            if (this.$store.state.prfInformado) {
                return '/login_profissional' 
            } else if (this.$store.state.usrInformado) {
                return '/login_usuario'
            } else {
                return '/'
            }
        },
        visualizarMenu() {
            window.scrollTo(0, 0);
            this.$store.state.menuSistema = true
        }
    }
}
</script>

<style>

</style>