<template>
    <v-container :id="namePage" fluid>
        <PageTitle :title="title" :subtitle="subtitle" :subtitleStrong="subtitleStrong" />
        <v-layout align-center justify-center>
            <slot></slot>
        </v-layout>
    </v-container>
</template>

<script>
import PageTitle from '@/components/templates/PageTitle'

export default {
    name: 'PageApp',
    props: [
        'namePage',
        'title',
        'subtitle',
        'subtitleStrong'
    ],
    components: {
        PageTitle
    }
}
</script>

<style>

</style>