<template>
    <PageApp namePage="cons-agendamentos" title="Consulte seus agendamentos" subtitle="Consulte os agendamentos marcados e realizados">
        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Se identifique: {{ usrNome }}</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card>
                        <v-card-text>
                            <v-form>
                                <v-text-field v-model="usuario.usr_email" label="E-mail" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="usuario.usr_senha" label="Senha" type="password" :color="consts.corNeutraEscura" dense />
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="mb-2 ml-2" @click="validarUsuario" :color="consts.corNeutraEscura" dark>Validar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Agendamentos marcados</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-if="usrId > 0">
                        <v-data-table hide-default-footer :headers="colunasMarcados" :items="agendamentos" no-data-text="Nenhum agendamento marcado" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                            <template v-slot:[`item.agd_data_hora`]="{ item }">
                                {{ functions.getDataHoraFormatada(item.agd_data_hora) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-btn @click="mostrarCancelamento(item)" :color="consts.corNeutraEscura" x-small dark>
                                    Cancelar
                                </v-btn>
                                <v-btn class="ml-2" @click="mostrarReagendamento(item)" :color="consts.corNeutraEscura" x-small dark>
                                    Reagendar
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                    <div v-else>
                        Usuário não validado.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Agendamentos realizados</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-if="usrId > 0">
                        <v-data-table hide-default-footer :headers="colunas" :items="agendamentosPast" no-data-text="Nenhum agendamento feito" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                            <template v-slot:[`item.agd_data_hora`]="{ item }">
                                {{ functions.getDataHoraFormatada(item.agd_data_hora) }}
                            </template>
                        </v-data-table>
                    </div>
                    <div v-else>
                        Usuário não validado.
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog v-model="mostrarCancel" max-width="400">
            <v-card>
                <v-card-title></v-card-title> <!-- apenas pra dar o espaçamento -->
                <v-card-text class="text-body-1">
                    <p :style="functions.getCssCorEscura()">
                        Confirma o cancelamento do agendamento?
                    </p>

                    <strong>Profissional: </strong> {{ agendamentoCancel.prf_nome }}
                    <br>
                    <strong>Serviço: </strong> {{ agendamentoCancel.srv_titulo }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancelarAgendamento(agendamentoCancel)" :color="consts.corNeutraEscura" text>
                        Sim
                    </v-btn>
                    <v-btn @click="ocultarCancelamento" :color="consts.corNeutraEscura" text>
                        Não
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="mostrarReagendar" max-width="400">
            <v-card v-if="diaSelec != ''">
                <v-card-title></v-card-title> <!-- apenas pra dar o espaçamento -->
                <v-card-text class="text-body-1">
                    <p :style="functions.getCssCorEscura()">
                        <strong>Altere o horário do agendamento</strong>
                    </p>

                    <strong>Empresa: </strong> {{ agendamentoReagendar.emp_fantasia }}
                    <br>
                    <strong>Profissional: </strong> {{ agendamentoReagendar.prf_nome }}
                    <br>
                    <strong>Serviço: </strong> {{ agendamentoReagendar.srv_titulo }}
                    <br>
                </v-card-text>

                <!-- Calendário e horários -->
                <v-card-text>
                    <v-sheet class="text-center">
                        <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.prevMonth(diaSelec)">
                            <v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span> {{ functions.getMesExtenso(diaSelec) }} </span>
                        <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.nextMonth(diaSelec)">
                            <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-sheet>
                    <v-sheet class="mt-4" height="500">
                        <v-calendar v-model="diaSelec" type="month" :weekday-format="functions.getDiaTraduzidoCalendario"> 
                            <template v-slot:day="{ date }">
                                <v-row class="mt-1 justify-space-around">
                                    <template v-if="(dias.indexOf(date) != -1) && (date != diaSelec)">
                                        <v-icon :color="consts.corNeutraClara">
                                            mdi-circle
                                        </v-icon>
                                    </template>
                                    <template v-if="date == diaSelec">
                                        <v-icon color="black">
                                            mdi-circle
                                        </v-icon>
                                    </template>
                                </v-row>
                            </template>
                        </v-calendar>
                    </v-sheet>
                    <v-sheet>
                        <p class="mt-5 mb-3 text-center"><strong>{{ functions.getDataString(diaSelec) }}</strong></p>
                        <div>
                            <v-select v-model="horaSelec" label="Selecione o horário" :items="functions.getHorariosDiaCombo(diaSelec, this.horarios)" :color="consts.corNeutraEscura"
                             no-data-text="Nenhum horário disponível para o dia" />
                        </div>
                    </v-sheet>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="reagendarAgendamento(agendamentoReagendar)" :color="consts.corNeutraEscura" text>
                        Confirmar
                    </v-btn>
                    <v-btn @click="ocultarReagendamento" :color="consts.corNeutraEscura" text>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

//OBS: Fonte muito semelhante ao "UsuarioPage.vue"

export default {
    name: 'ConsAgendamentos',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            mostrarCancel: false,
            mostrarReagendar: false,
            panels: [0],
            usrId: 0,
            usrNome: '',
            diaSelec: '',
            horaSelec: '',
            usuario: {},
            agendamentoCancel: {},
            agendamentoReagendar: {},
            agendamentos: [],
            agendamentosPast: [],
            horarios: [],
            dias: [],
            colunas: [
                {
                    text: 'Empresa',
                    value: 'emp_fantasia'
                },
                {
                    text: 'Profissional',
                    value: 'prf_nome'
                },
                {
                    text: 'Serviço',
                    value: 'srv_titulo'
                },
                {
                    text: 'Data/Hora',
                    value: 'agd_data_hora'
                },
                {
                    text: 'Preço',
                    value: 'agd_preco'
                },
                {
                    text: 'Tempo',
                    value: 'agd_tempo'
                }
            ],
            colunasMarcados: [
                {
                    text: 'Empresa',
                    value: 'emp_fantasia'
                },
                {
                    text: 'Profissional',
                    value: 'prf_nome'
                },
                {
                    text: 'Serviço',
                    value: 'srv_titulo'
                },
                {
                    text: 'Data/Hora',
                    value: 'agd_data_hora'
                },
                {
                    text: 'Preço',
                    value: 'agd_preco'
                },
                {
                    text: 'Tempo',
                    value: 'agd_tempo'
                },
                {
                    text: 'Ações',
                    value: 'btnActions'
                }
            ]
        }
    },
    methods: {
        cancelarAgendamento(item) {
            this.mostrarCancel = false

            const b = {
                agd_iid: item.agd_iid,
                prf_id: item.prf_id,
                srv_id: item.srv_id,
                agd_status: item.agd_status,
                usr_id: item.usr_id
            }

            axios.put(`${consts.baseApiUrl}/agendamentos/${item.agd_id}`, b)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.loadAgendamentosUsr()
                })
                .catch(functions.showError)
        },
        loadAgendamentosUsr() {
            this.agendamentos = []
            this.agendamentosPast = []

            axios.get(`${consts.baseApiUrl}/agendamentos_user/${this.usrId}`)
                .then(r => {
                    this.agendamentos = r.data.data
                })
                .catch(functions.showError)
            
            axios.get(`${consts.baseApiUrl}/agendamentos_user_past/${this.usrId}`)
                .then(r => {
                    this.agendamentosPast = r.data.data
                })
                .catch(functions.showError)
        },
        loadHorarios(prf_id, srv_id) {
            const dataIni = functions.getDataAgendamento(new Date(), true)

            let dataFim = functions.getDataAgendamento(new Date(), false)
            if (this.diaSelec) {
                dataFim = functions.getDataAgendamento(new Date(this.diaSelec), false)

                if (dataFim < dataIni) {
                    dataFim = functions.getDataAgendamento(new Date(), false)
                    this.diaSelec = ''
                }
            }

            axios.get(`${consts.baseApiUrl}/profissionais/${prf_id}/servicos/${srv_id}/horarios/${dataIni}/${dataFim}`)
                .then(r => {
                    let diaAtual = ''
                    r.data.data.forEach(el => {

                        const dAux = el.age_data_hora.substr(0, 10)

                        if (diaAtual != dAux) {
                            diaAtual = dAux

                            this.dias.push(dAux)

                            if (this.diaSelec == '') {
                                this.diaSelec = dAux
                            }
                        }

                        this.horarios.push({
                            ...el,
                            age_data_hora_calc: functions.getDataHoraFormatada(el.age_data_hora),
                            selected: false //Inicializa todos os horários como selected=false
                        })
                    })
                })
                .catch(functions.showError)
        },
        mostrarCancelamento(item) {
            this.agendamentoCancel = item
            this.mostrarCancel = true
        },
        mostrarReagendamento(item) {
            this.agendamentoReagendar = item

            this.horarios = []
            this.dias = []
            this.diaSelec = ''

            this.loadHorarios(this.agendamentoReagendar.prf_id, this.agendamentoReagendar.srv_id)
            this.mostrarReagendar = true
        },
        ocultarCancelamento() {
            this.agendamentoCancel = {}
            this.mostrarCancel = false
        },
        ocultarReagendamento() {
            this.agendamentoReagendar = {}
            this.mostrarReagendar = false
        },
        reagendarAgendamento(item) {
            const bAgd = { ...item }

            const aItem = this.horarios.filter(obj => {
                return obj.age_data_hora == this.horaSelec
            })

            this.horarios.forEach(el => {
                el.selected = false
            })

            const age = this.horarios.filter(obj => {
                return obj.age_id == aItem[0].age_id
            })

            const horario = aItem[0]
            age[0].selected = true

            bAgd.agd_data_hora = horario.age_data_hora

            axios.put(`${consts.baseApiUrl}/reagendar/${bAgd.agd_id}`, bAgd)
                .then(() => {
                    this.$toasted.global.msgSuccess('Reagendamento feito com sucesso!')
                    this.loadAgendamentosUsr()
                    this.mostrarReagendar = false
                })
                .catch(functions.showError)
        },
        validarUsuario() {
            this.usrId = 0
            this.usrNome = ''

            axios.put(`${consts.baseApiUrl}/validar_usuario`, this.usuario)
                .then(r => {
                    this.usrId = r.data.usr_id
                    this.usrNome = r.data.usr_nome

                    this.panels = [1, 2]

                    this.loadAgendamentosUsr()
                })
                .catch(functions.showError)
        }
    },
    mounted() {
        if (this.$store.state.usrInformado) {
            this.usrId = this.$store.state.usuario.usr_id
            this.usrNome = this.$store.state.usuario.usr_nome

            this.panels = [1, 2]

            this.loadAgendamentosUsr()
        }
    }
}
</script>

<style>

</style>