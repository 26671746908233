<template>
    <PageApp namePage="nova-empresa" title="Cadastre uma nova empresa" subtitle="Você pode utilizar a plataforma gratuitamente, com algumas limitações, por 30 dias e para 1 profissional">
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm10 md8 lg6>
                    <v-card elevation="4">
                        <v-card-text>
                            <v-form>
                                <v-text-field v-model="empresa.emp_razao_social" label="Razão social" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.emp_fantasia" label="Fantasia" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.emp_cnpj" label="CNPJ" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.emp_email" label="E-mail" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.emp_telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.emp_logradouro" label="Logradouro" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.emp_cidade" label="Cidade" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.emp_uf" label="UF" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.prf_nome" label="Nome do responsável (profissional)" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.prf_email" label="E-mail do responsável (profissional)" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.prf_telefone" label="Telefone do responsável (profissional)" v-mask="'(##)#####-####'" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.prf_senha" label="Senha" type="password" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.prf_senha_conf" label="Confirmação de senha" type="password" :color="consts.corNeutraEscura" dense />
                            </v-form>
                        </v-card-text>
                        <v-card-text>
                            <p class="text-left" style="font-weight: 300">Ao preencher o formulário acima, você está de acordo com a nossa 
                                <a href class="privacidade" @click.prevent="functions.openPrivacidade()">
                                    <strong>Política de Privacidade</strong>
                                </a>
                            </p>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="ml-2 mb-2" @click="cadastrarEmpresa" :color="consts.corNeutraClara" dark>Cadastrar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="mr-2 mb-2" @click="openLogin" :color="consts.corNeutraClara" dark outlined>Login do profissional</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'NovaEmpresa',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            empresa: {}
        }
    },
    methods: {
        cadastrarEmpresa() {
            const bEmpresa = {
                emp_razao_social: this.empresa.emp_razao_social,
                emp_fantasia: this.empresa.emp_fantasia,
                emp_cnpj: this.empresa.emp_cnpj,
                emp_email: this.empresa.emp_email,
                emp_telefone: functions.getFoneSemMascara(this.empresa.emp_telefone),
                emp_logradouro: this.empresa.emp_logradouro,
                emp_cidade: this.empresa.emp_cidade,
                emp_uf: this.empresa.emp_uf,
                prf_nome: this.empresa.prf_nome,
                prf_email: this.empresa.prf_email,
                prf_telefone: functions.getFoneSemMascara(this.empresa.prf_telefone),
                prf_senha: this.empresa.prf_senha,
                prf_senha_conf: this.empresa.prf_senha_conf,
            } 

            axios.post(`${consts.baseApiUrl}/criar_empresa`, bEmpresa)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.$router.push({
                        name: 'loginProfissional'
                    })
                })
                .catch(functions.showError)
        },
        openLogin() {
            this.$router.push({
                name: 'loginProfissional'
            })
        }
    }
}
</script>

<style>

</style>