<template>
    <v-main id="content-app">
        <v-container>
            <router-view></router-view>
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: 'ContentApp'
}
</script>

<style>

</style>