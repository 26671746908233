<template>
    <PageApp namePage="cad-profissionais" title="Cadastro de profissionais" subtitle="Cadastro dos profissionais da empresa">

        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Formulário</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-text-field v-model="profissional.prf_nome" label="Nome" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="profissional.prf_email" label="E-mail" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="profissional.prf_senha" label="Senha" type="password" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="profissional.prf_senha_conf" label="Confirme sua senha" type="password" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="profissional.prf_telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corNeutraEscura" dense />
                        <v-checkbox v-model="profissional.prf_agenda_externa" label="Agenda externa?" true-value="S" false-value="N" :color="consts.corNeutraEscura" dense />
                        <v-checkbox v-model="profissional.prf_admin" label="Administrador?" true-value="S" false-value="N" :color="consts.corNeutraEscura" dense />
                        <v-checkbox v-model="profissional.prf_ativo" label="Ativo?" true-value="S" false-value="N" :color="consts.corNeutraEscura" dense />
                    </v-form>
                    <v-row class="mt-3">
                        <v-btn class="ml-2 mb-2" v-if="mode === 'save'" @click="save" :color="consts.corNeutraClara" dark>Salvar</v-btn>
                        <v-btn class="ml-2 mb-2" v-if="mode === 'remove'" @click="remove" :color="consts.corNeutraEscura" dark>Excluir</v-btn>
                        <v-btn class="ml-2 mb-2" @click="reset" :color="consts.corClara">Cancelar</v-btn>
                    </v-row>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Listagem</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-listagem" hide-default-footer :headers="colunas" :items="profissionais"
                     no-data-text="Nenhum profissional cadastrado" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                        <template v-slot:[`item.btnActions`]="{ item }">
                            <v-btn class="mr-2" @click="loadProfissional(item)" :color="consts.corNeutraClara" x-small dark>Editar</v-btn>
                            <v-btn class="mr-2" @click="loadProfissional(item, 'remove')" :color="consts.corNeutraEscura" x-small dark>Remover</v-btn>
                            <v-btn class="mt-2 && mb-2" @click="abrirModalImagem(item)" :color="consts.corNeutraClara" x-small dark>Imagem</v-btn>
                        </template>
                    </v-data-table>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog v-model="modalImagem" max-width="500" max-height="1000" >
            <v-card>
                <v-card-title></v-card-title> <!-- apenas pra dar o espaçamento -->
                <v-card-text class="text-body-1">
                    <span :style="functions.getCssCorEscura()">
                        Adicione uma imagem:
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-file-input v-model="imagem" label="Clique aqui" accept="image/*" :color="consts.corNeutraEscura" dense prepend-icon="mdi-download" multiple /> 
                    <v-btn class="ml-8 my-2" @click="uploadImagem" :color="consts.corEscura" dark>Salvar imagem</v-btn> 
                </v-card-actions>
            </v-card>
        </v-dialog>

    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'CadProfissionais',
    components: {
        PageApp
    },
    data() {
        return {
            modalImagem: false,
            consts,
            functions,
            imagem: null,
            prf_id_imagem: 0,
            panels: [0, 1],
            mode: 'save',
            profissional: {},
            profissionais: [],
            colunas: [
                {
                    text: 'Código',
                    value: 'prf_iid'
                },
                {
                    text: 'Nome',
                    value: 'prf_nome'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortaable: false
                }
            ]
        }
    },
    methods: {
        loadProfissional(profissional, mode = 'save') {
            this.mode = mode
            this.profissional = { ...profissional }
        },
        loadProfissionais() {
            axios.get(`${consts.baseApiUrl}/profissionais`)
                .then(r => {
                    this.profissionais = r.data.data
                })
                .catch(functions.showError)
        },
        remove() {
            const id = this.profissional.prf_id
            axios.delete(`${consts.baseApiUrl}/profissionais/${id}`)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.profissional = {}
            this.loadProfissionais()
        },
        save() {
            const method = this.profissional.prf_id ? 'put' : 'post'
            const id = this.profissional.prf_id ? `/${this.profissional.prf_id}` : ''

            if (!this.profissional.prf_agenda_externa) {
                this.profissional.prf_agenda_externa = 'N'
            }

            if (!this.profissional.prf_admin) {
                this.profissional.prf_admin = 'N'
            }

            if (!this.profissional.prf_ativo) {
                this.profissional.prf_ativo = 'N'
            }

            const bProfissional = {
                prf_nome: this.profissional.prf_nome,
                prf_email: this.profissional.prf_email,
                prf_senha: this.profissional.prf_senha,
                prf_senha_conf: this.profissional.prf_senha_conf,
                prf_telefone: functions.getFoneSemMascara(this.profissional.prf_telefone),
                prf_agenda_externa: this.profissional.prf_agenda_externa,
                prf_admin: this.profissional.prf_admin,
                prf_ativo: this.profissional.prf_ativo,
            } 
            
            axios[method](`${consts.baseApiUrl}/profissionais${id}`, bProfissional)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        abrirModalImagem (item) {
            this.modalImagem = true
            this.prf_id_imagem = item.prf_id
        },
        uploadImagem() {
            if (this.imagem && this.imagem.length > 0) {
                const arquivo = this.imagem[0]

                if (arquivo.name.endsWith('.jpeg') || arquivo.name.endsWith('.png') || arquivo.name.endsWith('.jpg')){
                    const size = arquivo.size / 1024 
                    if(size > 200) {
                        return functions.showError('Arquivo inválido, tamanho maior que 200 KB') 
                    }
                } else {
                    return functions.showError('Arquivo não é uma imagem válida')
                }
                const formData = new FormData()
                formData.append('file', arquivo)
                axios.put(`${consts.baseApiUrl}/profissionais/adicionar_imagem/${this.prf_id_imagem}`, formData)
                    .then(r => {
                        if (r) {
                            functions.showSuccess(r.data.status)
                            this.modalImagem = false
                        } else {
                            functions.showError('Não foi possível anexar a imagem.')
                        }
                    })
                    .catch(erro => {
                        functions.showError(erro)
                    })
            } else {
                functions.showError('Imagem não informada')
            }
        }
    },
    mounted() {
        if (this.$store.state.prfInformado) {
            this.loadProfissionais()
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>
    .tabela-listagem tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, .03);
    }
</style>