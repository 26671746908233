    <template>
        <PageApp namePage="cad-agenda" title="Cadastro da agenda" subtitle="Cadastro da agenda do profissional da empresa">
            
            <v-expansion-panels v-model="panels" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">

                        <strong>Agenda: {{ functions.getDataString(diaSelec) }}</strong>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="criarAgenda = true" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Gerar agenda</span>
                        </v-tooltip>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-sheet class="text-center">
                            <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.prevMonth(diaSelec)">
                                <v-icon small>mdi-chevron-left</v-icon>
                            </v-btn>
                            <span> {{ functions.getMesExtenso(diaSelec) }} </span>
                            <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.nextMonth(diaSelec)">
                                <v-icon small>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-sheet>
                        <v-sheet class="mt-4" height="500">
                            <v-calendar v-model="diaSelec" type="month" :weekday-format="functions.getDiaTraduzidoCalendario"> 
                                <template v-slot:day="{ date }">
                                    <v-row class="mt-1 justify-space-around">
                                        <template v-if="(calendario.indexOf(date) != -1) && (date != diaSelec)">
                                            <v-icon :color="consts.corNeutraClara">
                                                mdi-circle
                                            </v-icon>
                                        </template>
                                        <template v-if="date == diaSelec">
                                            <v-icon color="black">
                                                mdi-circle
                                            </v-icon>
                                        </template>
                                    </v-row>
                                </template>
                            </v-calendar>
                        </v-sheet>

                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">

                        <strong>Horários: {{ functions.getDataString(diaSelec) }}</strong>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="mostrarExcluirAgenda" @click.prevent.stop icon max-width="40" x-small>
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Excluir agendas futuras</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="cancelarDia = true" @click.prevent.stop icon max-width="40" x-small>
                                    <v-icon>mdi-cancel</v-icon>
                                </v-btn>        
                            </template>
                            <span>Cancelar horários do dia</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="mostrarCriarHorario()" @click.prevent.stop icon max-width="40" x-small>
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>        
                            </template>
                            <span>Adicionar horário</span>
                        </v-tooltip>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-data-table class="tabela-listagem" hide-default-footer :headers="colunas" :items="horarios" 
                        :item-class="getStyleRow" no-data-text="Nenhuma agenda cadastrada para o dia" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">

                            <template v-slot:[`item.age_data_hora`]="{ item }">
                                {{ functions.getDataHoraString(item.age_data_hora) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-btn v-if="!item.agd_id && item.age_ativo == 'S'" @click="desativarAgenda(item)"
                                :color="consts.corNeutraEscura" x-small dark>
                                    Desativar
                                </v-btn>
                                <v-btn v-if="!item.agd_id && item.age_ativo == 'N'" @click="ativarAgenda(item)"
                                :color="consts.corNeutraEscura" x-small dark>
                                    Ativar
                                </v-btn>
                                <v-btn v-if="item.agd_id" @click="openAgendamento(item)" :color="consts.corNeutraClara" x-small dark>
                                    Agendamento
                                </v-btn>
                            </template>

                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <!-- Dialog para criar novas agendas -->
            <v-dialog v-model="criarAgenda" max-width="500">
                <v-card>
                    <v-card-title class="mb-3">Gerar nova agenda</v-card-title>
                    <v-card-text class="text-center">
                        
                        <v-date-picker locale="pt-br" v-model="intervalDates" range :color="consts.corNeutraEscura"/>
                        <v-text-field v-model="intervalDatesText" label="Intervalo selecionado" prepend-icon="mdi-calendar" readonly />

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="novaAgenda(intervalDates[0], intervalDates[1])" :color="consts.corNeutraEscura" text>
                            Gerar
                        </v-btn>
                        <v-btn @click="criarAgenda = false" :color="consts.corNeutraEscura" text>
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Dialog para criar um novo horário para a agenda -->
            <v-dialog v-model="criarHorario" max-width="400">
                <v-card>
                    <v-card-title class="mb-3">Novo horário da agenda</v-card-title>
                    <v-card-text>
                        
                        <v-row class="justify-center">
                            <v-time-picker v-model="newHorario" format="24hr" :color="consts.corNeutraEscura"
                            :header-color="consts.corNeutraClara" use-seconds small />
                        </v-row>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="novoHorarioAgenda(newHorario)" :color="consts.corNeutraEscura" text>
                            Salvar
                        </v-btn>
                        <v-btn @click="criarHorario = false" :color="consts.corNeutraEscura" text>
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Dialog para cancelar todos os horários do dia -->
            <v-dialog v-model="cancelarDia" max-width="400">
                <v-card>
                    <v-card-title class="mb-3">Cancelar toda a agenda do dia?</v-card-title>
                    <v-card-text>{{ functions.getDataString(diaSelec) }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="cancelarAgendaDia(diaSelec)" :color="consts.corNeutraEscura" text>
                            Sim
                        </v-btn>
                        <v-btn @click="cancelarDia = false" :color="consts.corNeutraEscura" text>
                            Não
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="excluirAgendas" max-width="400">
                <v-card>
                    <v-card-title>Excluir agendas futuras?</v-card-title>
                    <v-card-text>Seleciona um dia da semana e um horário</v-card-text>
                    <v-card-text class="text-center">
                        <v-select v-model="diaExcluir" :items="valoresDias" :color="consts.corNeutraEscura" />
                        <v-time-picker v-model="horaExcluir" format="24hr" :color="consts.corNeutraEscura" :header-color="consts.corNeutraClara" use-seconds small />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="excluirAgendasFuturas" :color="consts.corNeutraEscura" text>
                            Excluir
                        </v-btn>
                        <v-btn @click="excluirAgendas = false" :color="consts.corNeutraEscura" text>
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </PageApp>
    </template>

    <script>
    import PageApp from '@/components/templates/PageApp'
    import consts from '@/globalFiles/consts'
    import functions from '@/globalFiles/functions'
    import axios from 'axios'

    export default {
        name: 'CadAgenda',
        components: {
            PageApp
        },
        data() {
            return {
                consts,
                functions,
                diaSelec: '',
                criarAgenda: false,
                criarHorario: false,
                cancelarDia: false,
                excluirAgendas: false,
                diaExcluir: 'SEG',
                horaExcluir: '00:00:00',
                newHorario: '00:00:00',
                intervalDates: [functions.getDataWork(new Date()), functions.getDataWork(new Date())],
                panels: [0, 1],
                calendario: [],
                horarios: [],
                valoresDias: [
                    {
                        text: 'Segunda',
                        value: 'SEG'
                    },
                    {
                        text: 'Terça',
                        value: 'TER'
                    },
                    {
                        text: 'Quarta',
                        value: 'QUA'
                    },
                    {
                        text: 'Quinta',
                        value: 'QUI'
                    },
                    {
                        text: 'Sexta',
                        value: 'SEX'
                    },
                    {
                        text: 'Sábado',
                        value: 'SAB'
                    },
                    {
                        text: 'Domingo',
                        value: 'DOM'
                    }
                ],
                colunas: [
                    {
                        text: 'Data/Hora',
                        value: 'age_data_hora'
                    },
                    {
                        text: 'Agendamento',
                        value: 'agd_nome'
                    },
                    {
                        text: 'Ações',
                        value: 'btnActions',
                        sortable: false
                    }
                ]
            }
        },
        computed: {
            intervalDatesText() {                
                let s = ''
                if (this.intervalDates[0]) {
                    s = functions.getDataString(this.intervalDates[0])
                }

                if (this.intervalDates[1]) {
                    s = `${s} - ${functions.getDataString(this.intervalDates[1])}`
                }

                return s
            }
        },
        methods: {
            ativarAgenda(item) {
                this.updateAgenda(item.age_id, 'S')
            },
            cancelarAgendaDia(dia) {
                this.cancelarDia = false
                
                axios.put(`${consts.baseApiUrl}/agenda/cancel/${dia}`)
                    .then(() => {
                        this.$toasted.global.msgSuccess()
                        this.loadHorarios()
                    })
                    .catch(functions.showError)
            },
            desativarAgenda(item) {
                this.updateAgenda(item.age_id, 'N')
            },
            excluirAgendasFuturas() {
                this.excluirAgendas = false

                const pBody = {
                    dia: this.diaExcluir,
                    hora: this.horaExcluir
                }

                axios.put(`${consts.baseApiUrl}/agenda/delete`, pBody)
                    .then(() => {
                        this.$toasted.global.msgSuccess()
                        this.loadHorarios()
                    })
                    .catch(functions.showError)
            },
            getStyleRow(item) {
                if (item.age_ativo == 'N') {
                    return 'style_desativado'
                } else if (item.agd_id > 0) {
                    return 'style_agendado'
                } else {
                    return 'style_normal'
                }
            },
            loadAgenda() {
                this.calendario = []
                this.diaSelec = ''

                axios.get(`${consts.baseApiUrl}/agenda`)
                    .then(r => {
                        r.data.data.forEach(el => {

                            if (this.diaSelec == '') {
                                this.diaSelec = el.substr(0, 10)
                                this.loadHorarios()
                            }

                            this.calendario.push(el.substr(0, 10)) // 10 primeiras posições: yyyy-mm-dd
                        })
                    })
                    .catch(functions.showError)
            },
            loadHorarios() {
                this.horarios = []

                axios.get(`${consts.baseApiUrl}/agenda/${this.diaSelec} 00:00:00`)
                    .then(r => {
                        this.horarios = r.data.data
                    })
                    .catch(functions.showError)
            },
            mostrarCriarHorario() {
                this.newHorario = '00:00:00'
                this.criarHorario = true
            },
            mostrarExcluirAgenda() {
                this.diaExcluir = this.valoresDias[0].value
                this.horaExcluir = '00:00:00'

                this.excluirAgendas = true
            },
            novaAgenda(dIni, dFim) {
                axios.post(`${consts.baseApiUrl}/agenda`, {
                        data_ini: `${dIni} 00:00:00`,
                        data_fim: `${dFim} 00:00:00`
                    })
                    .then(() => {
                        this.criarAgenda = false
                        this.$toasted.global.msgSuccess()
                        this.loadAgenda()
                    })
                    .catch(functions.showError)
            },
            novoHorarioAgenda(hor) {
                this.criarHorario = false
                
                axios.post(`${consts.baseApiUrl}/agenda/new`, { age_data_hora: `${this.diaSelec} ${hor}`})
                    .then(() => {
                        this.$toasted.global.msgSuccess()
                        this.loadHorarios()
                    })
                    .catch(functions.showError)
            },
            openAgendamento(item) {
                this.$router.push({
                    name: 'agendamento',
                    params: {
                        agdId: item.agd_id
                    }
                })
            },
            updateAgenda(id, age_ativo) {
                axios.put(`${consts.baseApiUrl}/agenda/${id}`, {age_ativo})
                    .then(() => {
                        this.$toasted.global.msgSuccess()
                        this.loadHorarios()
                    })
                    .catch(functions.showError)
            }
        },
        watch: {
            diaSelec() {
                this.loadHorarios();
            },
            intervalDates: {
                handler(newDates) {
                    if (newDates[1] < newDates[0]) {
                        const temp = newDates[0];
                        newDates[0] = newDates[1];
                        newDates[1] = temp;
                    }
                },
                deep: true,
            },
        },
        mounted() {
            if (this.$store.state.prfInformado) {
                this.loadAgenda()
            } else {
                this.$router.push({
                    name: 'home'
                })
            }
        }
    }
    </script>

    <style>
        .style_desativado {
            background-color: rgb(255, 0, 0, .2) !important;
            color: black;
        }
        .style_agendado {
            background-color: rgb(0, 255, 0, .2) !important;
            color: black;
        }
        .style_normal {
            color: black;
        }
    </style>