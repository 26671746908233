import Vue from 'vue'
import consts from '@/globalFiles/consts'

//Funções que podem ser utilizadas/reaproveitadas no sistema inteiro

function copiarTextoClipboard(texto) {
    navigator.clipboard.writeText(texto)
        .then(() => {
                this.showSuccess('Senha copiada com sucesso!')
            },
            () => {
                this.showError('Não foi possível copiar a senha.')
            }
        )
}

function getCssCorClara() {
    return 'color: ' + consts.corClara
}

function getCssCorNeutraClara() {
    return 'color: ' + consts.corNeutraClara
}

function getCssCorNeutraEscura() {
    return 'color: ' + consts.corNeutraEscura
}

function getCssCorEscura() {
    return 'color: ' + consts.corEscura
}

function getDataWork(d) {
    return `${d.getFullYear()}-${(d.getMonth() +1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
}

function getDataAgendamento(d, ini) {
    if (ini) {
        return `${d.getFullYear()}-${(d.getMonth() +1).toString().padStart(2, '0')}-01 00:00:00`
    } else {
        //Pega o último dia do próximo mês
        const dAux = new Date(d.getFullYear(), d.getMonth() +2, 0)
        return `${dAux.getFullYear()}-${(dAux.getMonth() +1).toString().padStart(2, '0')}-${dAux.getDate().toString().padStart(2, '0')} 23:59:59`
    }
}

function getDataString(d) {
    const dd = d.substr(8, 2)
    const mm = d.substr(5, 2)
    const yy = d.substr(0, 4)
    return `${dd}/${mm}/${yy}`
}

function getDataHoraString(d) {
    const dmy = getDataString(d)
    const hms = d.substr(11, 8)
    return `${dmy} ${hms}`
}

function getDataFormatada(d) {
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() +1).toString().padStart(2, '0')}/${d.getFullYear()}`
}

function getDataHoraFormatada(strDate) {
    const vStrDate = strDate.replace(' ', 'T')
    const d = new Date(vStrDate)
    return `${getDataFormatada(d)} ${getHHFormat(d)}:${getMMFormat(d)}`
}

function getFoneSemMascara(telefone) {
    //replace não funciona em uma propriedade não definida (undefined...)
    if (telefone) {
        return telefone.replace('(', '').replace(')', '').replace('-', '')
    } else {
        return ''
    }
}

function getHHFormat(d) {
    return ("0" + d.getHours()).slice(-2)
}

function getHorariosDia(d, horarios) {
    return horarios.filter(obj => {
        return obj.age_data_hora.substr(0, 10) == d
    }).sort((a, b) => {
        return a.age_data_hora < b.age_data_hora ? -1 : a.age_data_hora > b.age_data_hora ? 1 : 0
    })
}

function getHorariosDiaCombo(d, horarios) {
    const ret = []

    this.getHorariosDia(d, horarios).forEach(el => {
        ret.push({
            text: el.age_data_hora_calc,
            value: el.age_data_hora
        })
    })

    return ret
}

function getMesExtenso(d) {
    return `${d.substr(5, 2)}/${d.substr(0, 4)}`
}

function getMMFormat(d) {
    return ("0" + d.getMinutes()).slice(-2)
}

function getSSFormat(d) {
    return ("0" + d.getSeconds()).slice(-2)
}

function getPrimeiroDiaMes() {
    const d = new Date()
    return `01/${(d.getMonth() +1).toString().padStart(2, '0')}/${d.getFullYear()}`
}

function getUltimoDiaMes() {
    const d = new Date()
    const dFim = new Date(d.getFullYear(), d.getMonth() +1, 0)
    return this.getDataFormatada(dFim)
}

function getDiaTraduzidoCalendario(date) {
    const weekdayNumber = new Date(date.date).getDay()
    const dias = ["SEG", "TER", "QUA", "QUI", "SEX", "SÁB", "DOM"];

    return dias[weekdayNumber]
}

function nextMonth(diaSelec) {
    let mAtual = parseInt(diaSelec.substr(5, 2))
    let aAtual = diaSelec.substr(0, 4)
    if (mAtual == 12) {
        return `${parseInt(aAtual) +1}-01-01`
    } else {
        return `${aAtual}-${(mAtual +1).toString().padStart(2, '0')}-01`
    }
}

function openSiteUrl(url) {
    //Abre o site em uma nova aba do navegador (_blank)
    window.open(url, '_blank').focus()
}

function openPrivacidade() {
    this.openSiteUrl('https://codezz.com.br/politica-privacidade')
}

function prevMonth(diaSelec) {
    let mAtual = parseInt(diaSelec.substr(5, 2))
    let aAtual = diaSelec.substr(0, 4)
    if (mAtual == 1) {
        return `${parseInt(aAtual) -1}-12-01`
    } else {
        return `${aAtual}-${(mAtual -1).toString().padStart(2, '0')}-01`
    }
}

function showError(e) {
    let msgErro

    if (e && e.response && e.response.data) {
        if (e.response.data.erro) {
            if (e.response.data.erro.sqlMessage) {
                msgErro = e.response.data.erro.sqlMessage
            } else {
                msgErro = e.response.data.erro
            }
        } else if (e.response.data.sqlMessage) {
            msgErro = e.response.data.sqlMessage
        } else {
            msgErro = e.response.data
        }
    } else if (typeof e === 'string') {
        msgErro = e
    } else {
        msgErro = ''
    }

    if (msgErro == '') {
        Vue.toasted.global.msgError()
    } else {
        Vue.toasted.global.msgError({
            msg: msgErro
        })
    }
}

function showSuccess(msg) {
    Vue.toasted.global.msgSuccess({
        msg
    })
}

export default {
    copiarTextoClipboard,
    getCssCorClara,
    getCssCorNeutraClara,
    getCssCorNeutraEscura,
    getCssCorEscura,
    getDataWork,
    getDataAgendamento,
    getDataString,
    getDataHoraString,
    getDataFormatada,
    getDataHoraFormatada,
    getFoneSemMascara,
    getHHFormat,
    getHorariosDia,
    getHorariosDiaCombo,
    getMMFormat,
    getMesExtenso,
    getSSFormat,
    getPrimeiroDiaMes,
    getUltimoDiaMes,
    getDiaTraduzidoCalendario,
    nextMonth,
    openSiteUrl,
    openPrivacidade,
    prevMonth,
    showError,
    showSuccess
}