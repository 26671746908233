<template>
    <PageApp namePage="profissional-page" title="Página do profissional" subtitle="Seja bem vindo, "
     :subtitleStrong="$store.state.profissional.prf_nome">

        <v-col>
            
            <!-- Empresa bloqueada (2) -->
            <v-alert v-if="tipoBloqueio == 2" dense type="error">
                A empresa está bloqueada, {{ consts.msgContatoCodezz }}
            </v-alert>

            <!-- Empresa liberada, porém mostra mensagem de aviso (1) = Aviso é apresentado com 5 dias de antecedência -->
            <v-alert v-if="tipoBloqueio == 1" dense type="warning">
                Atenção, a empresa será bloqueada {{ this.diasBloqueio == 0 ? 'amanhã' : `em ${this.diasBloqueio} dia(s)` }}, {{ consts.msgContatoCodezz }}
            </v-alert>

            <!-- Conteúdo da página principal do profissional -->
            <v-expansion-panels v-if="tipoBloqueio != 2" v-model="panels" multiple>

                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Notificações</strong>
                        <v-spacer></v-spacer>
                        <v-btn @click="loadNotificacoes" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                            <v-icon>mdi-reload</v-icon>
                        </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-data-table class="tabela-notificacoes" hide-default-footer :headers="colunasNotificacoes" :items="notificacoes" 
                         no-data-text="Nenhuma notificação para o dia" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                            <template v-slot:[`item.not_data_hora`]="{ item }">
                                {{ functions.getDataHoraFormatada(item.not_data_hora) }}
                            </template>
                            <template v-slot:[`item.agd_data_hora`]="{ item }">
                                {{ functions.getDataHoraFormatada(item.agd_data_hora) }}
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Atendimentos do dia</strong>
                        <v-spacer></v-spacer>
                        <v-btn @click="loadPagina" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                            <v-icon>mdi-reload</v-icon>
                        </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-data-table class="tabela-atendimentos" hide-default-footer :headers="colunasAtendimento" :items="atendimentosDia" 
                        no-data-text="Nenhum atendimento para o dia" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                            <template v-slot:[`item.template_nome`]="{ item }">
                                {{ item.agd_cliente_interno ? item.agd_cliente_interno : item.usr_nome }}
                            </template>
                            <template v-slot:[`item.agd_data_hora`]="{ item }">
                                {{ functions.getDataHoraFormatada(item.agd_data_hora) }}
                            </template>
                        </v-data-table>

                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Próximos horários disponíveis</strong>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-data-table class="tabela-horarios" hide-default-footer :headers="colunasHorarios" :items="horariosLivres" 
                        no-data-text="Nenhum horário livre na agenda" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                            <template v-slot:[`item.age_data_hora`]="{ item }">
                                {{ functions.getDataHoraFormatada(item.age_data_hora) }}
                            </template>
                        </v-data-table>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        </v-col>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import functions from '@/globalFiles/functions'
import consts from '@/globalFiles/consts'
import axios from 'axios'

export default {
    name: 'ProfissionalPage',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            panels: [1, 2],
            tipoBloqueio: 2, //0=Liberado; 1=Aviso; 2=Bloqueado
            diasBloqueio: 0,
            atendimentosDia: [],
            horariosLivres: [],
            notificacoes: [],
            colunasAtendimento: [
                {
                    text: 'Cliente',
                    value: 'template_nome'
                },
                {
                    text: 'Serviço',
                    value: 'srv_titulo'
                },
                {
                    text: 'Data/Hora',
                    value: 'agd_data_hora'
                },
            ],
            colunasHorarios: [
                {
                    text: 'Data/Hora',
                    value: 'age_data_hora'
                }
            ],
            colunasNotificacoes: [
                {
                    text: 'Data/Hora',
                    value: 'not_data_hora'
                },
                {
                    text: 'Serviço',
                    value: 'srv_titulo'
                },
                {
                    text: 'Agendamento',
                    value: 'agd_data_hora'
                },
                {
                    text: 'Cliente',
                    value: 'usr_nome'
                },
                {
                    text: 'Status',
                    value: 'not_status'
                }
            ]
        }
    },
    methods: {
        loadNotificacoes() {
            this.notificacoes = []
            axios.get(`${consts.baseApiUrl}/notificacoes`)
                .then(r => {
                    this.notificacoes = r.data.data
                })
                .catch(functions.showError)
        },
        loadPagina() {
            this.atendimentosDia = []
            axios.get(`${consts.baseApiUrl}/agendamentos_dia`)
                .then(r => {
                    r.data.data.forEach(el => {
                        this.atendimentosDia.push({
                            usr_nome: `${el.usr_nome} ${el.usr_sobrenome}`,
                            agd_cliente_interno: el.agd_cliente_interno,
                            srv_titulo: el.srv_titulo,
                            agd_data_hora: el.agd_data_hora
                        })
                    })
                })
                .catch(functions.showError)

            this.horariosLivres = []
            axios.get(`${consts.baseApiUrl}/agenda_livre`)
                .then(r => {
                    r.data.data.forEach(el => {
                        this.horariosLivres.push({
                            age_data_hora: el
                        })
                    })
                })
                .catch(functions.showError)
        }
    },
    async mounted() {
        if (this.$store.state.prfInformado) {
            const empPrf = {
                prf_id: this.$store.state.profissional.prf_id,
                emp_id: this.$store.state.profissional.emp_id
            }

            this.tipoBloqueio = 2 //Bloqueado
            this.diasBloqueio = 0

            await axios.put(`${consts.baseApiUrl}/empresa_liberada`, empPrf)
                .then(res => {
                    if (res.data.aviso) {
                        this.tipoBloqueio = 1 //Aviso
                        this.diasBloqueio = res.data.dias_aviso
                    } else if (res.data.liberado) {
                        this.tipoBloqueio = 0 //Liberado
                    } else {
                        this.tipoBloqueio = 2 //Bloqueado
                    }
                })
                .catch(functions.showError)
            
            if (this.tipoBloqueio != 2) {
                this.loadPagina()
                this.loadNotificacoes()
            }
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>

</style>