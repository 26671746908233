<template>
    <v-app id="app">
        <HeaderApp />
        <MenuApp />
        <LoadingPage v-if="validatingToken" />
        <ContentApp v-else />
        <FooterApp />
    </v-app>
</template>

<script>
import HeaderApp from '@/components/pageStructure/HeaderApp.vue'
import MenuApp from '@/components/pageStructure/MenuApp.vue'
import LoadingPage from '@/components/pageStructure/LoadingPage.vue'
import ContentApp from '@/components/pageStructure/ContentApp.vue'
import FooterApp from '@/components/pageStructure/FooterApp.vue'
import consts from '@/globalFiles/consts'
import axios from 'axios'

export default {
    name: 'App',
    components: {
        HeaderApp,
        MenuApp,
        LoadingPage,
        ContentApp,
        FooterApp
    },
    data() {
        return {
            validatingToken: true
        }
    },
    methods: {
        async validateToken() {
            this.validatingToken = true

            const jsonPrf = localStorage.getItem(consts.userKeyProfissional)
            const jsonUsr = localStorage.getItem(consts.userKeyUsuario)

            const prfData = JSON.parse(jsonPrf)
            const usrData = JSON.parse(jsonUsr)

            this.$store.commit('setProfissional', null)
            this.$store.commit('setUsuario', null)

            if (!prfData && !usrData) {
                this.validatingToken = false
                return
            }

            if (prfData) {
                const resPrf = await axios.post(`${consts.baseApiUrl}/validar_token`, prfData)

                if (resPrf.data) {
                    this.$store.commit('setProfissional', prfData)
                } else {
                    localStorage.removeItem(consts.userKeyProfissional)
                }
            }

            if (usrData) {
                const resUsr = await axios.post(`${consts.baseApiUrl}/validar_token_user`, usrData)

                if (resUsr.data) {
                    this.$store.commit('setUsuario', usrData)
                } else {
                    localStorage.removeItem(consts.userKeyUsuario)
                }
            }

            this.validatingToken = false
        }
    },
    created() {
        this.validateToken()
    }
}
</script>

<style>
    * {
        font-family: 'Roboto', sans-serif;
    }
</style>
