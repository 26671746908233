<template>
    <div id="page-title">
        <br>
        <span class="text-h5" :style="functions.getCssCorEscura()">
            <strong>{{ title }}</strong>
        </span>
        <p :style="functions.getCssCorEscura()">
            {{ subtitle }}
            <strong>{{ subtitleStrong }}</strong>
        </p>
        <hr>
        <br>
    </div>
</template>

<script>
import functions from '@/globalFiles/functions'

export default {
    name: 'PageTitle',
    props: [
        'title',
        'subtitle',
        'subtitleStrong'
    ],
    data() {
        return {
            functions
        }
    }
}
</script>

<style>

</style>