<template>
    <v-main id="loading-page">
        <v-container class="mt-10">
            <v-col class="text-center">
                <strong>Aguarde...</strong> 
            </v-col>
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: 'LoadingPage'
}
</script>

<style>

</style>