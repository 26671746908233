import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/config/vuetify'
import router from '@/config/router'
import store from '@/config/store'
import VueLazyLoad from 'vue-lazyload'
import { VueMaskDirective } from 'v-mask'

import '@/config/msgs'

Vue.config.productionTip = true

Vue.use(VueLazyLoad)

//Biblioteca de terceiro para utilizar máscaras (de telefone por exemplo) dentro dos componentes Vuetify
Vue.directive('mask', VueMaskDirective);

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
