<template>
    <PageApp namePage="cad-agendamentos" title="Cadastro de um agendamentos" subtitle="Cadastro de um agendamento do profissional da empresa">
        
        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Serviço: {{ servico.srv_titulo }}</strong>
                    <v-spacer></v-spacer>
                    <v-btn @click="reset" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                        <v-icon>mdi-reload</v-icon>
                    </v-btn>
                </v-expansion-panel-header>
                
                <v-expansion-panel-content>

                    <v-list three-line>
                        <template v-for="(srv, i) in servicos">
                            <v-list-item :key="i">
                                <v-list-item-content>
                                    <v-card :class="srv.selected ? 'card-servico-selec' : ''" @click="selectServico(srv)" :color="consts.corClara" outlined>
                                        <v-card-title class="text-subtitle-2">{{ srv.srv_titulo }}</v-card-title>
                                        <v-card-text class="pt-0" v-if="srv.srv_descricao">{{ srv.srv_descricao }}</v-card-text>
                                        <v-card-text class="pt-0" v-if="srv.srv_preco > 0">Preço: R$ {{ srv.srv_preco }}</v-card-text>
                                    </v-card>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Horário: {{ horario.age_data_hora_calc }}</strong>
                    <v-spacer></v-spacer>
                    <v-btn v-if="horarios.length > 0" @click="loadHorarios" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <div v-if="!servicoSelecionado">
                            Nenhum serviço selecionado!
                    </div>
                    <div v-else>
                        <div v-if="buscandoAgenda"> 
                            <v-progress-circular indeterminate></v-progress-circular>
                                Carregando agenda...   
                        </div>
                        <div v-else>
                            <v-sheet class="text-center">
                                <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.prevMonth(diaSelec)">
                                    <v-icon small>mdi-chevron-left</v-icon>
                                </v-btn>
                                <span> {{ functions.getMesExtenso(diaSelec) }} </span>
                                <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.nextMonth(diaSelec)">
                                    <v-icon small>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-sheet>
                            <v-sheet class="mt-4" height="500">

                                <v-calendar v-model="diaSelec" type="month" :weekday-format="functions.getDiaTraduzidoCalendario"> 
                                    <template v-slot:day="{ date }">
                                        <v-row class="mt-1 justify-space-around">
                                            <template v-if="(dias.indexOf(date) != -1) && (date != diaSelec)">
                                                <v-icon :color="consts.corNeutraClara">
                                                    mdi-circle
                                                </v-icon>
                                            </template>
                                            <template v-if="date == diaSelec">
                                                <v-icon color="black">
                                                    mdi-circle
                                                </v-icon>
                                            </template>
                                        </v-row>
                                    </template>
                                </v-calendar>
                            
                            </v-sheet>
                            <v-sheet>
                                <p class="mt-5 mb-3 text-center"><strong>{{ functions.getDataString(diaSelec) }}</strong></p>

                                <div v-if="mostrarComboBox">
                                    <v-select v-model="horaSelec" label="Selecione o horário" :items="functions.getHorariosDiaCombo(diaSelec, this.horarios)" :color="consts.corNeutraEscura"
                                    no-data-text="Nenhum horário disponível para o dia" />
                                    <v-btn @click="selectHorarioCombo(horaSelec)" :color="consts.corNeutraEscura" dark>
                                        Selecionar
                                    </v-btn>
                                </div>
                                <v-data-table v-else hide-default-footer :headers="colunas" :items="functions.getHorariosDia(diaSelec, this.horarios)" :items-per-page="-1" 
                                :item-class="getStyleRow" no-data-text="Nenhum horário disponível para o dia." :header-props="{sortByText: 'Ordenar por...'}">

                                    <template v-slot:[`item.btnActions`]="{ item }">
                                        <v-btn @click="selectHorario(item)" :color="consts.corNeutraEscura" dark>
                                            Selecionar
                                        </v-btn>
                                    </template>

                                </v-data-table>
                            </v-sheet>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Agendamento</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <div v-if="!agendando">
                        Nenhum horário selecionado!
                    </div>
                    <div v-else>
                        
                        <v-text-field v-model="agendamento.agd_cliente_interno" label="Cliente" :color="consts.corNeutraEscura" dense />
                        <v-btn class="mt-2" @click="agendarHorario" :color="consts.corNeutraClara" dark>Agendar</v-btn>

                    </div>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import functions from '@/globalFiles/functions'
import consts from '@/globalFiles/consts'
import axios from 'axios'

export default {
    name: 'CadAgendamentos',
    components: {
        PageApp
    },
    data() {
        return {
            buscandoAgenda: false,
            servicoSelecionado: false,
            functions,
            consts,
            mostrarComboBox: true, //Caso um dia seja criado uma configuração na empresa pra mostrar ou não mostrar o combo
            agendando: false,
            diaSelec: '',
            horaSelec: '',
            dias: [],
            panels: [0],
            servicos: [],
            servico: {},
            horarios: [],
            horario: {},
            agendamento: {},
            colunas: [
                {
                    text: 'Data/Hora',
                    value: 'age_data_hora_calc'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        agendarHorario() {
            axios.post(`${consts.baseApiUrl}/agendamentos`, this.agendamento)
                .then(() => {
                    this.$toasted.global.msgSuccess('Agendamento criado com sucesso!')
                    
                    this.reset()
                })
                .catch(functions.showError)
        },
        getStyleRow(item) {
            if (item.selected) {
                return 'style_selecionado'
            } else {
                return ''
            }
        },
        inicializarAgendamento() {
            this.agendando = true
            this.agendamento = {
                prf_id: this.$store.state.profissional.prf_id,
                //usr_id: null,
                agd_cliente_interno: '',
                srv_id: this.servico.srv_id,
                agd_data_hora: this.horario.age_data_hora,
                agd_preco: this.servico.srv_preco,
                agd_tempo: `${this.servico.srv_horas}:${this.servico.srv_minutos}:00`,
            }
        },
        loadHorarios() {
            this.buscandoAgenda = true
            this.agendando = false
            //this.diaSelec = '' -- Essa variável não precisa ser zerada!!
            this.dias = []
            this.horarios = []
            this.horario = {}
            this.agendamento = {}

            const dataIni = functions.getDataAgendamento(new Date(), true)

            let dataFim = functions.getDataAgendamento(new Date(), false)
            if (this.diaSelec) {
                dataFim = functions.getDataAgendamento(new Date(this.diaSelec), false)

                if (dataFim < dataIni) {
                    dataFim = functions.getDataAgendamento(new Date(), false)
                    this.diaSelec = ''
                }
            }

            axios.get(`${consts.baseApiUrl}/profissionais/${this.$store.state.profissional.prf_id}/servicos/${this.servico.srv_id}/horarios/${dataIni}/${dataFim}`)
                .then(r => {
                    let diaAtual = ''
                    r.data.data.forEach(el => {

                        const dAux = el.age_data_hora.substr(0, 10)

                        if (diaAtual != dAux) {
                            diaAtual = dAux

                            this.dias.push(dAux)

                            if (this.diaSelec == '') {
                                this.diaSelec = dAux
                            }
                        }

                        this.horarios.push({
                            ...el,
                            age_data_hora_calc: functions.getDataHoraFormatada(el.age_data_hora),
                            selected: false //Inicializa todos os horários como selected=false
                        })
                    })
                    this.buscandoAgenda = false
                })
                .catch(functions.showError)
        },
        loadServicos() {
            this.servicoSelecionado = false
            this.agendando = false
            this.servicos = []
            this.servico = {}
            this.dias = []
            this.diaSelec = ''
            this.horarios = []
            this.horario = {}
            this.agendamento = {}
            
            axios.get(`${consts.baseApiUrl}/profissionais/${this.$store.state.profissional.prf_id}/servicos`)
                .then(r => {
                    r.data.data.forEach(el => {
                        this.servicos.push({ 
                            ...el,
                            selected: false //Inicializa todos os serviços como selected=false
                        })
                    })
                })
                .catch(functions.showError)
        },
        reset() {
            this.loadServicos()
            this.panels = [0]
        },
        selectHorario(item) {
            if (item != this.horario) {
                this.horarios.forEach(el => {
                    el.selected = false
                })

                const age = this.horarios.filter(obj => {
                    return obj.age_id == item.age_id   
                })

                this.panels = [2]
                this.horario = item
                age[0].selected = true

                this.inicializarAgendamento()
            } 
        },
        selectHorarioCombo(data) {
            const aItem = this.horarios.filter(obj => {
                return obj.age_data_hora == data
            })

            if (aItem.length <= 0) {
                functions.showError('Você deve selecionar um horário.')
            } else {
                this.selectHorario(aItem[0])
            }
        },
        selectServico(item) {
            this.servicoSelecionado = false
            if (item !== this.servico) {
                this.servicos.forEach(el => {
                    el.selected = false
                })
    
                const srv = this.servicos.filter(obj => {
                    return obj.srv_id == item.srv_id
                })
    
                this.panels = [1]
                this.servico = item
                srv[0].selected = true

                this.servicoSelecionado = true
                this.loadHorarios()
            }
        }
    },
    mounted() {
        if (this.$store.state.prfInformado) {
            this.loadServicos()
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>
    .card-servico-selec {
        border: 5px solid rgb(38, 73, 92) !important;
    }
    .style_selecionado {
        color: rgb(0, 0, 255);
        font-weight: bold;
    }
</style>