<template>
    <PageApp namePage="selecionar-empresa" title="Selecione uma empresa" subtitle="Selecione a empresa para agendar um horário">
        <v-card width="800">
            <v-card-title>Selecione a empresa</v-card-title>
            <v-card-text>
                <v-data-table hide-default-footer :headers="colunas" :items="empresas" :items-per-page="-1" no-data-text="Nenhuma empresa disponível." :header-props="{sortByText: 'Ordenar por...'}">
                    <template v-slot:[`item.btnActions`]="{ item }">
                        <v-btn @click="openEmpresa(item)" :color="consts.corNeutraEscura" x-small dark>
                            Selecionar
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'SelecionarEmpresa',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            empresas: [],
            colunas: [
                {
                    text: 'Empresa',
                    value: 'emp_fantasia'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        openEmpresa(item) {
            this.$router.push({
                path: `/agendar/${item.emp_link}`
            })
        }
    },
    mounted() {
        axios.get(`${consts.baseApiUrl}/empresas`)
            .then(r => {
                this.empresas = r.data.data
            })
            .catch(functions.showError)
    }
}
</script>

<style>

</style>